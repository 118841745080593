import instLogo from "./assets/images/InstagramLogo.png";
import fbLogo from "./assets/images/facebookLogo.png";
import bgImage from "./assets/images/Logo_PERFYLES.png";
import phoneIcon from "./assets/images/phoneIcon.png";
import adressIcon from "./assets/images/adressIcon.png";

function NavHeader() {
    return (
        <div>
            <div
            style={{
                backgroundColor: 'black',
                paddingTop: '10px',
                paddingBottom: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <img
                src={instLogo}
                style={{
                    width: '30px',
                    marginRight: '10px',
                }}
            />
            <img
                src={fbLogo}
                style={{
                    width: '30px',
                    marginLeft: '10px',
                }}
            />
        </div>

        <div
            style={{
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap', // Permite que los elementos se envuelvan en pantallas pequeñas
                padding: '20px', // Añade espacio entre los elementos
            }}
        >
            <div style={{ marginBottom: '20px', flex: '1 1 100%', maxWidth: '350px' }}>
                {/* Estilos del contenedor del logo */}
                <a href="/" >
                <img
                    src={bgImage}
                    alt="Logo"
                    style={{
                        width: '100%',
                        display: 'block',
                    }}
                />
                </a>
            </div>
            <div style={{ marginBottom: '20px', flex: '1 1 50px', textAlign: 'center' }}>
                {/* Estilos del contenedor del número de teléfono */}
                <img
                    src={phoneIcon}
                    alt="Logo"
                    style={{
                        width: '50px',
                        height: '50px',
                        margin: '0 auto', // Centra la imagen horizontalmente
                    }}
                />
                <p style={{ margin: '10px 0 0' }}>809.750.0716 / 809.988.6210</p>
            </div>
            <div style={{ marginBottom: '20px', flex: '1 1 50px', textAlign: 'center' }}>
                {/* Estilos del contenedor de la dirección */}
                <img
                    src={adressIcon}
                    alt="Logo"
                    style={{
                        width: '50px',
                        height: '50px',
                        margin: '0 auto', // Centra la imagen horizontalmente
                    }}
                />
                <p style={{ margin: '10px 0 0' }}>
                    Avenida Tiradentes #40 esquina Presidente González, Naco, edificio ESPIRIT
                </p>
            </div>
            </div>
        </div>
    );
}

export default NavHeader;