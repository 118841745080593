import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes

import { routes_logout } from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "axios";
import { CenterFocusStrong } from "@mui/icons-material";

//Componentes
import userService from "../../../services/user.service"

import { contextToken } from '../../Context';
import { useUser } from "../../Context";


// import { BrowserRouter, Routes, Route } from "react-router-dom";

function AthletaExistente() {

  const {setContextAthleteID} = useUser();
  const navigate = useNavigate();

  const [athlete, setAthlete] = useState(null);
  const [findAthlete, setFindAthlete] = useState("");
 // const url = new URL(window.location)
 // const userID = url.searchParams.get("userID")
 // const userName = url.searchParams.get("userName")
  const [evaluaciones, setEvaluaciones] = useState([]);
 // const role = url.searchParams.get("no");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    async function fetchData(){
      setIsLoading(true);
      const atletas = await userService.getAllAthletes()
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error al realizar la solicitud, favor contactar al administrador',
              text: error.response.data.message,
            });
            setIsLoading(false);
          });

      const pruebaAsginada = await userService.getAssignedTests()
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error al realizar la solicitud, favor contactar al administrador',
              text: error.response.data.message,
            });
            setIsLoading(false);
          });
      setIsLoading(false);
      setAthlete(atletas.data);
      setEvaluaciones(pruebaAsginada.data)
      ;

    }

    fetchData();

  },[] );

  const processedAthletes = new Set(); // conjunto para almacenar IDs de atletas procesados
  const usuariosYEvaluaciones = evaluaciones.map(evaluacion => {
    const usuario = athlete.find(usuario => evaluacion.athleteId === usuario.id);
    if (usuario && !processedAthletes.has(usuario.id)) { // si el atleta existe y no ha sido procesado antes
      processedAthletes.add(usuario.id ); // lo agregamos al conjunto de atletas procesados
      return { ...usuario, evaluacion };
    } else {
      return null; // si el atleta ya ha sido procesado o no existe, retornamos null
    }
  }).filter(result => result !== null); // eliminamos los resultados nulos (atletas repetidos o no encontrados)



  const searchChange = event => {
    setFindAthlete(event.target.value);
  };

 const manejarKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // Puedes agregar tu lógica personalizada aquí si es necesario
    }
  };
  
    return (
    <>
      {/* <DefaultNavbar routes={routes_logout}/> */}
      <MKBox
        position="absolute"
        width="100%"
        height="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6) 
            )}, url(${bgImage})`,
            backgroundSize: "100% 100%",
          backgroundAttachment: "fixed"
        }}
        />
        <MKBox px={1} width="100%" height="70%" mx="auto" position="relative" zIndex={2} top={90}>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
           <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={1}
                mt={1}
                p={0}
                mb={1}
                  
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Lista de Atletas
                </MKTypography>
              </MKBox>

              <MKBox pt={4} pb={3} px={3}>

                <MKBox component="form" role="form">

                <MKBox mb={2}>
                  <MKInput type="filter" label="NOMBRE, APELLIDO, DEPORTE, CLUB" fullWidth
                      onChange={searchChange}
                      onKeyDown={manejarKeyDown}
                  />
                 
                </MKBox>

                  <MKBox mb={2}>


                    <div style={{ textAlign: "left" }}>
                      {isLoading ? (
                          <p>Cargando...</p>
                      ) : (
                      usuariosYEvaluaciones != null  ? (
                          usuariosYEvaluaciones
                              .filter((player) => {
                                return findAthlete.toLowerCase() === ''
                                    ? player
                                    : player.firstName.toLowerCase().includes(findAthlete) +
                                    player.lastName.toLowerCase().includes(findAthlete) +
                                    player.sport.toLowerCase().includes(findAthlete) +
                                    player.club.toLowerCase().includes(findAthlete)
                                    ;
                              })
                              .map(usuarioYEvaluacion => (
                                  <li key={usuarioYEvaluacion.id} style={{ marginBottom: "10px" }}>
                                    <b>Nombre:</b>{usuarioYEvaluacion.firstName.toUpperCase()}&nbsp;
                                    <b>Apellido:</b>{usuarioYEvaluacion.lastName.toUpperCase()}&nbsp;
                                    <b>Deporte:</b>{usuarioYEvaluacion.sport.toUpperCase()}&nbsp;
                                    <b>Club:</b>{usuarioYEvaluacion.club.toUpperCase()}&nbsp;
                                    <a 
                                    style={{ paddingLeft:'10px'}} 
                                    // href={`/evaluador/${usuarioYEvaluacion.id}?userID=${userID}&userName=${userName}&uiat=${usuarioYEvaluacion.evaluacion.uiAt}&no=${role}`}>Evaluar
                                    href="#" // You can use a placeholder href
                      onClick={(e) => {
                      e.preventDefault(); // Prevent the default link behavior
                    //  setContextUserID(usuario.id);
                      setContextAthleteID(usuarioYEvaluacion.id);
                      navigate(`/evaluador/${usuarioYEvaluacion.id}`);
                       }}>Evaluar
                                    
                                    </a>
                                  </li>
                              ))
                      ): (
                          <p>No hay data</p>
                      )
                      )}
                    </div>

                  </MKBox>
                  <MKBox mb={2}>
                  </MKBox>
        
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
          </Grid>
     
      </MKBox>  
      
     
     
    </>
    
  );
}



export default AthletaExistente;
