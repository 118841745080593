import axios from "axios";
import authHeader from './auth-header';

//const API_URL = 'http://localhost:8088/api/auth/';  //LOCAL
const API_URL = 'http://www.perfyles.com:8088/api/auth/'; //PROD
//const API_URL = 'http://159.203.72.253:8088/api/auth/';


class AuthService {

  login(
      {username, password}
    ) {
    return axios
      .post(API_URL + "signin", {
        email: username,
        password : password
      })
      .then(response => {
        if (response.data.accessToken) {
            console.log("Token OK")
        }

        return response.data;
      });
  }

   logout() {
       sessionStorage.removeItem("user");
   }

  register(
      firstName,
      lastName,
      password,
      email,
      pin,
      sysCreationDate,
      role,
      activeInd
  ) {
    return axios.post(API_URL + "signup", {
          firstName: firstName,
          lastName: lastName,
          password: password,
          email: email,
          pin:pin,
          sysCreationDate :sysCreationDate,
          role: role,
          activeInd: activeInd
    }, {headers: authHeader()});
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('user'));
  }

}

export default new AuthService();
