/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";


// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import routes from "routes";


// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import Swal from 'sweetalert2';
import AuthService from "../../../services/auth.service";
import userService from "../../../services/user.service";
import { useUser } from '../../Context';

function SignInBasic() 
{
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [athleteID, setAthleteID] = useState(0);
  const {setContextUsername,setContextToken, setLoggedUserRole,setContextUserID, setContextTestID, setContextAdminUserID, setContextAthleteID} = useUser();
 
  const navigate = useNavigate();

  const userChange = event => 
    {
      setUsername(event.target.value);
    };

  const passwordChange = event => 
    {
      setPassword(event.target.value);
    };
  
     //PARA BORRAR EL TOKEN CADA VEZ QUE EL USUARIO VUELVA A LA PANTALLA DE LOGIN
    AuthService.logout();
    

    useEffect(() => 
      {
        async function fetchData()
        {
          //const atletas = await userService.getAllAthletes();
          // const prueba = await userService.getAssignedTests();
          //  setAthlete(atletas.data);
          //  setContextTestID(prueba.data.testId);
          //  setContextUiat(prueba.data.uiAt);
        }
  
        fetchData();
  
      },[]);

    const handleClick = async () => 
      {
        AuthService.login({ username, password })
          .then(data => 
            {
              if (data.accessToken !== null) 
                {
                  const userRole = data.roles;
                  const actInd = data.activeInd;
                  const userID = data.id;
                  const userName = data.username;
                  const token = data.accessToken; ///guardarlo en el contexto para evitar que se borre

                  //console.log(data);

                  setContextUserID(userID);
                  setContextUsername(userName);
                  setLoggedUserRole(userRole);
                  //setContextToken(token);
                 
                  if (userRole === "ADM" && actInd === 'Y') 
                    {
                      sessionStorage.setItem("user", JSON.stringify(token));
                      navigate('/administrador')
                    } 
                  else if (userRole === "MOD" && actInd === 'Y' ) 
                    {
                      sessionStorage.setItem("user", JSON.stringify(token));
                      navigate('/evaluar')
                    }
                  else if(userRole === "USER" && actInd === 'Y' )
                    { 
                      sessionStorage.setItem("user", JSON.stringify(token));
                      userService.getRemoteUser(userID)
                      .then(result => 
                        {
                          const athleteID = result.data[0].athleteId;
                          const userID = result.data[0].userId;
                          setAthleteID(athleteID);
                          setContextAthleteID(athleteID);
                          setContextAdminUserID(userID);
                          if (athleteID > 0) 
                            {
                              sessionStorage.setItem("user", JSON.stringify(token));
                              navigate(`/evaluador/${athleteID}`);
                            }
                        })
                          .catch(error => 
                            {
                              Swal.fire(
                                {
                                  icon: 'error',
                                  title: 'Error',
                                  text: error.response.data.message,
                                })
                            });
                    }
                  else
                    {
                      Swal.fire(
                        {
                          icon: 'error',
                          title: 'Error',
                          text: 'Error al iniciar sesion, favor intentar nuevamente.',
                        })
                        .then((result) => 
                          {
                            if (result.isConfirmed) 
                              {
                                // Refrescar la página
                                window.location.reload();
                              }
                           });
                    }
                }

            })
              .catch(error => 
                {
                  //console.log(error)
                  if (error.message === "Network Error") 
                    {
                      //DATABASE CONECTION ERROR
                      Swal.fire(
                          {
                            icon: 'error',
                            title: 'Error al conectarse con la base de datos',
                            text: error.response.data.message,
                          });
                    }
                  else if(error.response.data.error === "Unauthorized" && error.response.data.message === "Bad credentials")
                    {
                      // Respuesta no válida (usuario o contraseña incorrectos)
                      Swal.fire(
                        {
                          icon: 'error',
                          title: 'Usuario o contraseña incorrectos, por favor intenta nuevamente.',
                          text: error.response.data.message,
                        });
                    }
                  else
                    {
                      //GENERAL ERRORS
                      Swal.fire(
                        {
                          icon: 'error',
                          title: 'Error al realizar la solicitud, si el error persiste, contacta al administrador',
                          text: error.response.data.message,
                        });
                    }
                });
      };
   
    return (
    <>
       {/* <DefaultNavbar routes={routes} /> */}

      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}
             sx={{
                 backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                     `${linearGradient(
                         rgba(gradients.dark.main, 0.6),
                         rgba(gradients.dark.state, 0.6)
                     )}, url(${bgImage})`,
                 backgroundSize: "cover",
                 backgroundPosition: "center",
                 backgroundRepeat: "no-repeat",
             }}
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sign in
                </MKTypography>

              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput type="username" label="Username" fullWidth
                     //onChange={(val) => setUsername(val)}
                     onChange={userChange} />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type="password" label="Password" fullWidth
                    //onChange={(val) => setPassword(val)}
                    onChange={passwordChange}
                    />
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth
                      onClick={handleClick}>
                      sign in
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>

      </MKBox>
    </>
  );
}

export default SignInBasic;
