import { Link, Navigate, useNavigate } from "react-router-dom";
// react-router-dom components
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";



// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { routes_logout } from "routes";
import SessionTimeout from "pages/timeout";
import { useUser } from '../../Context';


// import { BrowserRouter, Routes, Route } from "react-router-dom";

const Evaluar = () => {
  
  const navigate = useNavigate();
   

//  const url = new URL(window.location)
 // const userID = url.searchParams.get("userID");
 // const userName = url.searchParams.get("userName");
 // const role = url.searchParams.get("no");



     function handleClick(valor)  {

      if(valor == "NUEVO"){
       
        navigate('/AtletaNuevo')

      }else if(valor == "EXISTENTE"){

        
        navigate('/AtletaExistente')

      }else if(valor == "NoTests"){

        
        navigate('/NoTest')
      }

          }

       
            // const handleTimeout = () => {
            //   navigate("/pages/authentication/sign-in")
            // };
          
       

    return (
    <>
      <DefaultNavbar routes={routes_logout} />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}
             sx={{
               backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                   `${linearGradient(
                       rgba(gradients.dark.main, 0.6),
                       rgba(gradients.dark.state, 0.6)
                   )}, url(${bgImage})`,
               backgroundSize: "cover",
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
             }}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Evaluar Atleta
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox display="flex" alignItems="center" ml={-1}>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth 
                      onClick={() => handleClick("NUEVO")} >
                       Atleta Nuevo
                    </MKButton>
                  </MKBox>

                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth 
                      onClick={() => handleClick("EXISTENTE")}
                      >
                       Atleta Existente
                    </MKButton>
                  </MKBox>

                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth 
                      onClick={() => handleClick("NoTests")}
                      >
                       Atleta sin prueba asignada
                    </MKButton>
                  </MKBox>
                  {/* <SessionTimeout timeoutDuration={20000} onTimeout={handleTimeout} /> */}
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
     
      </MKBox>  
     
    </>
    
  );
}




export default Evaluar;
