import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "axios";
import { CenterFocusStrong } from "@mui/icons-material";
import TextField from "assets/theme/components/form/textField";
import MKDatePicker from "components/MKDatePicker";
import { routes_logout } from "routes";
import moment from 'moment';
import SessionTimeout from "pages/timeout";
import userService from "../../../services/user.service";
import { useUser } from '../../Context';
import Swal from 'sweetalert2';

// import { BrowserRouter, Routes, Route } from "react-router-dom";

function ResumenEncuesta() {

  const navigate = useNavigate();
  const { contextAthleteID,contextUserID,ContextUiat, contextTestID, contextTestName, contextAdminUserID,LoggedUserRole } = useUser();
  
  let test = "";
  let uiat = "";
  let userID = 0;
  let athleteID = 0;
  let testID = 0;
  let userName = "";

  const [athlete, setAthlete] = useState("")
  const params = useParams()
  const [preguntas, setPreguntas] =useState([]);
  const [answers, setAnswers] =useState([]);
  const [creationDate, setCreationDate] = useState("");
  const [remoteInd, setRemoteInd] = useState("");
  const [remoteUserId, setRemoteUserId] = useState(0);
  const [testByID, setTestByID] = useState([]);


  const url = new URL(window.location)
  test= contextTestName
  if(LoggedUserRole === 'USER')
  {
     userID=contextAdminUserID
   //  console.log(userID);
   } 
  else{
    userID=contextUserID
  //  console.log(userID);
  }
   uiat= ContextUiat
  athleteID= contextAthleteID
  testID=contextTestID
 // userName=url.searchParams.get("userName")
  const role = url.searchParams.get("no");

  //console.log("TestID",testID )
  
  var date = moment().utcOffset('-04:00').format('yyyyMMDDHHmmss');

    
   const allQuestions  = async () => { 
    await userService.getQuestionsResume(userID,athleteID,test,uiat)
    .then(response => 
      {
        console.log("RESPUESTA",response.data)
      }).catch(error =>  
      {
        if(!error.response.data.success)
          {
            Swal.fire
            ({
              icon: 'error',
              title: 'Error',
              text: error.response.data.message,
            })
            .then((result) => 
            {
            if (result.isConfirmed) 
            {
                // Refrescar la página
                navigate('/pages/authentication/sign-in', {replace:true})
            }
        });
      }
      //console.log("Consulta Error:", error.response.data.success)
    })
     
      const questionsData = await userService.getQuestionsData(uiat,test)
     // const athleteTests =  await userService.getAssignedTestID(athleteID)
      const assignedTestID = await userService.getAssignedTestByID(testID)
      console.log ("TestID",assignedTestID);
   
       
   setPreguntas(questionsData.data)
   setCreationDate(assignedTestID.data.sysCreationDate)
   setRemoteInd(assignedTestID.data.remoteInd)
   setRemoteUserId(assignedTestID.data.remoteUserId)
   



     //  console.log(athleteTests.data)

     }

     

  useEffect(() => {
    allQuestions();
  },[] ); 


  const resultado = preguntas.map(function(data2){
    return{
      uiAt: data2[0],
      itemNo: data2[1],
      itemString: data2[2],
      item: data2[3],
      userId: data2[4],
      athletesId: data2[5]
    };
  });

  const handleClick = () => {

//ACTUALIZANDO EL TEST A COMPLETADO
    const assignedTest = {
      "id":testID,
      "athleteId": athleteID,
      "userId": userID,
      "testId": test,
      "uiAt":uiat,
      "sysCreationDate": creationDate,  
      "completeDate":date,
      "completeInd": "Y",
      "remoteInd" : remoteInd,
      "remoteUserId" : remoteUserId
     };

    // console.log(assignedTest);

      
      userService.postAssginedTest(assignedTest)
          .then(response => {
            if (response.status === 201) {
            
            navigate(`/evaluador/`+athleteID)
              return response.json(); // Si la respuesta es exitosa, convierte la respuesta en formato JSON
            } else {
              throw new Error('Error en la solicitud');
            }
          })
          .then(data => {
            // Utiliza los datos de la respuesta
            console.log('ok');
          })
          .catch(error => {
            // Maneja el error
            console.error(error);
          });

  }


    return (
    <>
      {/* <DefaultNavbar routes={routes_logout}/>  */}
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={2}
        width="100%"
        minHeight="100vh"
        mx="auto"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6) 
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          
        }}
      />
      <MKBox px={1} width="100%" height="70%" mx="auto" position="relative" zIndex={2} top={4}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={1}
                mt={1}
                p={0}
                mb={1}
                  
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Resumen
                </MKTypography>
              </MKBox>
              
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                {resultado.map((pregunta) => (
                   
                    <div key={pregunta}>
      
                     <h3>{pregunta.itemNo + ". " + pregunta.itemString}</h3> 

                     {(() => {
                       switch(test) {
                         case 'TCPRD':
                           switch (pregunta.item) {
                             case 1:
                               return <p style={{color: 'black'}}>Totalmente en desacuerdo</p>;
                             case 2:
                               return <p style={{color: 'black'}}>Bastante en desacuerdo</p>;
                             case 3:
                               return <p style={{color: 'black'}}>Un poco de acuerdo</p>;
                             case 4:
                               return <p style={{color: 'black'}}>Bastante de acuerdo</p>;
                             case 5:
                               return <p style={{color: 'black'}}>Totalmente de acuerdo</p>;
                             default:
                               return <p style={{color: 'black'}}>Respuesta invalida</p>;
                           }
                         case 'CC-10':
                           switch (pregunta.item) {
                             case 1:
                               return <p style={{color: 'black'}}>Casi nunca</p>;
                             case 2:
                               return <p style={{color: 'black'}}>Algunas veces</p>;
                             case 3:
                               return <p style={{color: 'black'}}>A menudo</p>;
                             default:
                               return <p style={{color: 'black'}}>Respuesta invalida</p>;
                           }
                         case 'TCSAI':
                           switch (pregunta.item) {
                             case 1:
                               return <p style={{color: 'black'}}>De ningún forma</p>;
                             case 2:
                               return <p style={{color: 'black'}}>Algo</p>;
                             case 3:
                               return <p style={{color: 'black'}}>Moderadamente</p>;
                             case 4:
                               return <p style={{color: 'black'}}>Mucho</p>;
                             default:
                               return <p style={{color: 'black'}}>Respuesta invalida</p>;
                           }
                         case 'LOEHR':
                           switch (pregunta.item) {
                             case 1:
                               return <p style={{color: 'black'}}>Casi Siempre</p>;
                             case 2:
                               return <p style={{color: 'black'}}>A Menudo</p>;
                             case 3:
                               return <p style={{color: 'black'}}>A Veces</p>;
                             case 4:
                               return <p style={{color: 'black'}}>Casi Nunca</p>;
                             default:
                               return <p style={{color: 'black'}}>Respuesta invalida</p>;
                           }
                         case 'MINBO':
                           switch (pregunta.item) {
                             case 1:
                               return <p style={{color: 'black'}}>Verdadero</p>;
                             case 2:
                               return <p style={{color: 'black'}}>A Falso</p>;
                             default:
                               return <p style={{color: 'black'}}>Respuesta invalida</p>;
                           }
                       }
                          })()}
                    

                    </div>
                   
                    ))}

                    <MKBox mt={4} mb={3} xl={10}>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <MKButton
                            variant="gradient"
                            color="info"
                            style={{ marginBottom: '10px' }}
                            size="large"
                            onClick={handleClick}
                        >
                            Finalizar
                        </MKButton>
                    </div>

                </MKBox>
               {/*      <MKBox mt={4} mb={3} xl={10}>
                    <MKButton variant="gradient" color="warning"  
                      onClick={backClick}>
                       Volver                       
                    </MKButton>
 
                    
                    </MKBox> */}
        
                    {/* <SessionTimeout timeoutDuration={10000} onTimeout={handleTimeout} /> */}
                    </MKBox>
                
              </MKBox>
            </Card>
          </Grid>
        </Grid>
     
      </MKBox>  
     
    </>
    
  );
}

export default ResumenEncuesta;
