// @mui icons

// Images
import logoCT from "assets/images/perfyles_logo.png";

export default {
  brand: {
    name: "Developed by SONDREXS",
    image: logoCT,
    route: "/",
  },
  
  socials: [],
  menus: [],

  /*   copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Material Kit by{" "}
      <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Creative Tim
      </MKTypography>
      .
    </MKTypography>
  ), */
};
