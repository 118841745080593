import {useEffect, useState} from "react";
import { Navigate, useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import {routes_logout} from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import Swal from "sweetalert2";

import { useUser } from '../../Context';
import {shoNewNotificatios } from "../../../services/notificationService";
import userService from "../../../services/user.service";


function Administrador() {

    const [notifications, setNotifications] = useState([]);
    const [error, setError] = useState(null);


    const {contextToken} = useUser();

  //userService.getUserToken(contextToken);
  const userDataJSON = AuthService.getCurrentUser();

    const navigate = useNavigate();

     if (userDataJSON === null){
         Swal.fire({
             icon: 'error',
             title: 'Error',
             text: 'Error al iniciar sesion, favor intentar nuevamente.',
         }).then((result) => {
             if (result.isConfirmed) {

               navigate('/pages/authentication/sign-in')
             }
         });
     }

   // console.log(userDataJSON.accessToken);
  //const url = new URL(window.location);
 // const userID = url.searchParams.get("userID");
 // const userName = url.searchParams.get("userName");
  //const role = url.searchParams.get("no");


/// load notificatios pending for show ///
    // useEffect(() => {
    //     const fetchNotifications = async () => {
    //         try {
    //             const response = await userService.getAllNewNotifications();
    //             setNotifications(response.data);
    //         } catch (error) {
    //             setError(error.message);
    //             console.error("Error fetching data: ", error);
    //         }
    //     };

    //     fetchNotifications();

    // }, []);

    // useEffect(() => {
    //     if (notifications){
    //         // complete the notifications load for no show more times //
    //         try {
    //         userService.updateAllnewNotificatiosAsComplete()
    //         } catch (error) {
    //             setError(error.message);
    //             console.error("Error fetching data: ", error);
    //         }

    //     notifications.forEach((notification, index) => {
    //         setTimeout(() => {
    //             shoNewNotificatios(notification.notificationText);
    //         }, 2000 * (index + 1));
    //     });
    //     }
    // }, [notifications]);


    function handleClick(valor)  {

      //Esto debe hacerse con un CASE
      if(valor === "Evaluar"){

        navigate('/evaluar')
      }else if(valor === "Crear"){

       navigate('/crearPerfil')
      }else if (valor === "Completadas"){

        navigate('/evalCompletadas')
      }else if (valor === "Incompletas"){

       navigate('/evalIncompletas')
      }
     /*  })
      .catch(error => {
        alert("wrong username or password, please try again")
        console.log(error)
      }) */
  }

    return (
    <>
        <DefaultNavbar routes={routes_logout}/>

      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}

             sx={{
               backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                   `${linearGradient(
                       rgba(gradients.dark.main, 0.6),
                       rgba(gradients.dark.state, 0.6)
                   )}, url(${bgImage})`,
               backgroundSize: "cover",
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
             }}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Administrador
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox display="flex" alignItems="center" ml={-1}>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth
                      onClick={() => handleClick("Evaluar")} >
                       Evaluar
                    </MKButton>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth
                      onClick={() => handleClick("Crear")} >
                       Administrar perfiles
                    </MKButton>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth
                      onClick={() => handleClick("Completadas")} >
                       Evaluaciones completadas
                    </MKButton>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth
                      onClick={() => handleClick("Incompletas")} >
                       Evaluaciones pendientes
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>

      </MKBox>

    </>

  );
}

export default Administrador;
