import { useState } from "react";
import { useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "axios";

// import { BrowserRouter, Routes, Route } from "react-router-dom";

function Evaluar() {

  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  const userChange = event => {
    setUsername(event.target.value);
  };

  const passwordChange = event => {
    setPassword(event.target.value);
  };
     const handleClick = () => {axios
    .get(`http://localhost:8088/api/users/validUser`, {
      params: { email: username, password: password }},
      )
      .then((response) => {
           
      const userRole = response.data[0].role;

      console.log(userRole);

      if(userRole == 0){
        navigate('/pages/authentication/sign-in')

      }else{

       // routes=[{ label: "Home", route: "/presentation" }]
        
      }
      })
      .catch(error => {
        alert("wrong username or password, please try again")
        console.log(error)
      })
  }

    return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Atleta
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox display="flex" alignItems="center" ml={-1}>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth 
                      onClick={handleClick}>
                       Atleta 1
                    </MKButton>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="info" fullWidth 
                      onClick={handleClick}>
                       Atleta 2
                    </MKButton>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
     
      </MKBox>  
     
    </>
    
  );
}

export default Evaluar;
