import {useEffect, useState} from "react";
import { Navigate, useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import {routes_logout} from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "axios";
import {CenterFocusStrong} from "@mui/icons-material";
import moment from 'moment';
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";

import userService from "../../../services/user.service"

import AtletaNuevo from "../AtletaNuevo/atletaNuevo";
import { useUser } from '../../Context';

function Evaluaciones() {

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [cedula, setCedula] = useState("");
    const [role, setRole] = useState("");
    const [users, setUsers] = useState([]);
   // const [completeInd, setCompleteInd] = useState(false);
    const {setContextFirstName, setContextLastName, setContextPassword,setContextEmail,setContextCedula, setNewUserRole, setContextCreateDate, setContextUiat, contextToken} = useUser();
    //console.log(contextToken);
   // const url = new URL(window.location)
   // const urluserID = url.searchParams.get("userID")
   // const urluserName = url.searchParams.get("userName")
    //const getRole = url.searchParams.get("no");
    const [mostrarAtletaNuevo, setMostrarAtletaNuevo] = useState(false);
    const [runUseEffect, setRunUseEffect] = useState(false);
    
    const navigate = useNavigate();


    useEffect(() => {

        async function fetchData() {
            const getUsers = await userService.getUsers();
            
            setUsers(getUsers.data)
            ;

        }

        fetchData();

    }, [runUseEffect]);
    

  

    const activeUsers = users.filter(usuario => usuario.activeInd === 'Y');

    const nameChange = event => {
        setFirstname(event.target.value);
    };
    const lastNameChange = event => {
        setLastname(event.target.value);
    };

    const passwordChange = event => {
        setPassword(event.target.value);
    };

    const emailChange = event => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const value = event.target.value;
        setEmail(value);
        setIsValid(emailPattern.test(value));
        // setEmail(event.target.value);
    };

    const cedulaChange = event => {
        setCedula(event.target.value);
    };


    /// new method ///
    const handleClick = () => {

        if (role === "USER"){
            
            setContextFirstName(firstname);
            setContextLastName(lastname);
            setContextPassword(password);
            setContextEmail(email);
            setContextCedula(cedula);
            setContextCreateDate( moment().utcOffset('-04:00').format('yyyyMMDDHHmmss'));
            setNewUserRole(role);
            setContextUiat('Y')

            navigate('/AtletaNuevo')

//            setUserID(urluserID);
            //setCompleteInd(true);
            

        }else{

        AuthService.register(
            firstname,
            lastname,
            password,
            email,
            cedula,
            moment().utcOffset('-04:00').format('yyyyMMDDHHmmss'),
            role, 
            "Y"
            ).then( response =>{
                console.log(response.data.message)

         Swal.fire({
             icon: 'success',
             title: 'Correcto',
             text: 'USUARIO CREADO',
         }).then((result) => {
           
              if (result.isConfirmed) {
           if(runUseEffect){
            setRunUseEffect(false)
            } 
            else 
            {
                setRunUseEffect(true)
            }
            setFirstname("");
            setLastname("");
            setPassword("");
            setEmail("");
            setCedula("");
                }
         })
        }).catch ( error => {
            console.log(error);
              if (!error.response.data.success){
                Swal.fire({
                    icon: 'error',
                    title: 'Error creando usuario, favor validar e intentar nuevamente.',
                    text: error.response.data.message,
                })
            }
         }

         )
         }
    }

    const handleAdmin = async (e) => {
        const checked = e.target.checked
        if (checked == true) {

            setRole("ADMIN")

        }
    };

    const handleEvaluador = async (e) => {
        const checked = e.target.checked
        if (checked == true) {

            setRole("MOD")
        }
    };

    const handleAthlete = async (e) => {
        const checked = e.target.checked
        if (checked == true) {

            setRole("USER")
        }
    };

    const deactivateUser = async (userID) => {
        userService.getActivateUser(userID)
               .then((response) => {

                const userData = response.data;

                const postData =
                    {
                        "id": userData.id,
                        "firstName": userData.firstName,
                        "lastName": userData.lastName,
                        "email": userData.email,
                        "pin": userData.pin,
                        "sysCreationDate": userData.sysCreationDate,
                        "role": userData.role,
                        "activeInd": "N",
                    }

                    userService.patchUser(postData, userData.id)
// Refrescar la página para que se vuelva a ejecutar el useEffect y se muestren los cambios de la consulta a la DB  
if(runUseEffect){
    setRunUseEffect(false)
    } 
    else 
    {
        setRunUseEffect(true)
    }
            })
  

    }

    const activateUser = async (userID) => {
        userService.getActivateUser(userID)
            .then((response) => {

                const userData = response.data;

                console.log(response.data)

                const postData =
                    {
                        "id": userData.id,
                        "firstName": userData.firstName,
                        "lastName": userData.lastName,
                        "email": userData.email,
                        "pin": userData.pin,
                        "sysCreationDate": userData.sysCreationDate,
                        "role": userData.role,
                        "activeInd": "Y",
                    }

                userService.patchUser(postData, userData.id)

                if(runUseEffect){
                    setRunUseEffect(false)
                    } 
                    else 
                    {
                        setRunUseEffect(true)
                    }
            })

    }

    const administrar = () => {
        
        navigate('/administrador')
    }

    return (
        <>
        
            {/* <DefaultNavbar routes={routes_logout}/> */}

            <MKBox px={1} width="100%" height="100%" mx="auto" position="absolute" zIndex={1} 
                   sx={{
                       backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                           `${linearGradient(
                               rgba(gradients.dark.main, 0.6),
                               rgba(gradients.dark.state, 0.6)
                           )}, url(${bgImage})`,
                       backgroundSize: "cover",
                       backgroundPosition: "center",
                       backgroundRepeat: "no-repeat",
                   }}>
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                    <Grid item xs={11} sm={9} md={5} lg={4} xl={8}>
                        <Card>
                            <MKBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={1}
                                mt={1}
                                p={0}
                                mb={1}

                                textAlign="center"
                            >
                                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Crear Perfil
                                </MKTypography>
                            </MKBox>
                            <MKBox pt={4} pb={3} px={3}>
                                <MKBox component="form" role="form">
                                    <MKBox mb={2}>
                                        <label htmlFor="firstname">Nombres:</label>
                                        <MKInput type="text" value={firstname} fullWidth
                                            //onChange={(val) => setUsername(val)}
                                                 onChange={nameChange}/>
                                    </MKBox>

                                    <MKBox mb={2}>
                                        <label htmlFor="lastname">Apellidos:</label>
                                        <MKInput type="text" value={lastname} fullWidth
                                            //onChange={(val) => setUsername(val)}
                                                 onChange={lastNameChange}/>

                                    </MKBox>

                                    <MKBox mb={2}>
                                        <label htmlFor="password">Contraseña:</label>
                                        <MKInput type="password" value={password} fullWidth
                                            //onChange={(val) => setPassword(val)}
                                                 onChange={passwordChange}
                                        />
                                    </MKBox>

                                    <MKBox mb={2}>
                                        <label htmlFor="email">Correo electrónico:</label>
                                        <MKInput type="text" id="email" value={email} fullWidth
                                            //onChange={(val) => setUsername(val)}
                                                 onChange={emailChange}
                                                 style={{borderColor: isValid ? "green" : "red"}}/>
                                        {!isValid &&
                                            <div style={{color: "red"}}>Por favor, ingrese un correo electrónico
                                                válido</div>}

                                    </MKBox>
                                    <MKBox mb={2}>
                                        <label htmlFor="cedula">Cédula:</label>
                                        <MKInput type="text" value={cedula} fullWidth
                                            //onChange={(val) => setUsername(val)}
                                                 onChange={cedulaChange}/>

                                    </MKBox>

                                    <MKBox variant="gradient"
                                           bgColor="white"
                                           borderRadius="lg"
                                           coloredShadow="info"
                                           mx={1}
                                           mt={1}
                                           p={1}
                                           mb={3}
                                           width="auto"
                                           maxWidth="40%"
                                           height="10%">
                                        <label>
                                            <input style={{marginLeft: '25px', marginBottom: '10px'}}
                                                   type="checkbox"
                                                   onChange={handleEvaluador}
                                            />
                                            Evaluador
                                        </label>

                                        <label>
                                            <input style={{marginLeft: '25px', marginBottom: '10px'}}
                                                   type="checkbox"
                                                   onChange={handleAdmin}
                                            />
                                            Adiministrador
                                        </label>

                                        <label>
                                            <input style={{marginLeft: '25px', marginBottom: '10px'}}
                                                   type="checkbox"
                                                   onChange={handleAthlete}
                                            />
                                            Atleta
                                        </label>
                                    </MKBox>

                                    <MKBox mt={4} mb={1}>
                                        <MKButton variant="gradient" color="info"
                                                  onClick={(event) => {
                                                      event.preventDefault();
                                                      handleClick()
                                                  }} style={{marginLeft: '25px', marginBottom: '10px'}}
                                        >
                                            Crear usuario

                                        </MKButton>

                                        <MKButton variant="gradient" color="info"
                                                  onClick={administrar}
                                                  style={{marginLeft: '25px', marginBottom: '10px'}}>
                                            Volver
                                        </MKButton>
                                    </MKBox>
                                </MKBox>
                                <MKBox
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    mx={1}
                                    mt={1}
                                    p={0}
                                    mb={1}

                                    textAlign="center"
                                >
                                    <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                        Usuarios activos
                                    </MKTypography>
                                </MKBox>
                                {users.filter(usuario => usuario.activeInd === 'Y')
                                    .map(usuario => {
                                        // Buscamos la evaluaciĆ³n correspondiente al usuario
                                        const evaluacionUsuario = activeUsers.find(evaluacion => evaluacion.athleteId === usuario.id);
                                        // Si hay una evaluaciĆ³n completada para el usuario, la mostramos
                                        return (
                                            <li key={usuario.id}>
                                                <b>Nombre:</b>{usuario.firstName.toUpperCase()}&nbsp;
                                                <b>Apellido:</b>{usuario.lastName.toUpperCase()}&nbsp;
                                                <b>Activo:</b>{usuario.activeInd.toUpperCase()}&nbsp;


                                                <button onClick={(event) => {
                                                    event.preventDefault();
                                                    deactivateUser(usuario.id)
                                                }}>Desactivar Usuario
                                                </button>
                                            </li>

                                        );

                                    })}
                                <MKBox
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    mx={1}
                                    mt={1}
                                    p={0}
                                    mb={1}

                                    textAlign="center"
                                >
                                    <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                        Usuarios inactivos
                                    </MKTypography>

                                </MKBox>
                                {users.filter(usuario => usuario.activeInd === 'N')
                                    .map(usuario => {
                                        // Buscamos la evaluaciĆ³n correspondiente al usuario
                                        const evaluacionUsuario = activeUsers.find(evaluacion => evaluacion.athleteId === usuario.id);
                                        // Si hay una evaluaciĆ³n completada para el usuario, la mostramos
                                        return (
                                            <li key={usuario.id}>
                                                <b>Nombre:</b>{usuario.firstName.toUpperCase()}&nbsp;
                                                <b>Apellido:</b>{usuario.lastName.toUpperCase()}&nbsp;
                                                <b>Activo:</b>{usuario.activeInd.toUpperCase()}&nbsp;
                                                <button onClick={(event) => {
                                                    event.preventDefault();
                                                    activateUser(usuario.id)
                                                }}>Activar Usuario
                                                </button>
                                            </li>

                                        );

                                    })}
                            </MKBox>
                        </Card>
                    </Grid>
                </Grid>
       
            </MKBox>
    
        </>

    );
}

export default Evaluaciones;



