/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import ContactUS from "pages/Presentation/sections/ContactUS";
import Download from "pages/Presentation/sections/Download";

// Presentation page components

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/Logo_PERFYLES.png";


//EFECTOS
import AOS from 'aos';
import 'aos/dist/aos.css';
import {useEffect} from "react";
import bgServicios from "../../../assets/images/serviciosBG.jpg";
import NavHeader from "../../../NavHeaderIndex";

function Presentation() {
    useEffect(() => {
        AOS.init({
            offset: 200, // desplazamiento antes de la aparición del elemento
            duration: 1000, // duración de la animación en milisegundos
            easing: 'ease-in-out', // tipo de interpolación para la animación
            delay: 100, // tiempo de espera antes de que comience la animación
            once: true // si la animación solo se reproduce una vez
        });
    }, []);
  return (
    <>
        <NavHeader/>
      <DefaultNavbar routes={routes} />

            <Grid container item justifyContent="center" mx="auto">
                <MKBox
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '500px', // Altura del banner
                        width: '100%',
                        backgroundImage: `url(${bgServicios})`, // Ruta de la imagen de fondo
                        backgroundSize: 'cover', // Ajusta el tamaño de la imagen al contenedor
                        backgroundPosition: "20% 30%",
                       marginBottom: '40px',
                    }}
                >
                    <h1
                        style={{
                            textAlign: 'center',
                            paddingBottom: '20px',
                            paddingTop: '60px',
                            color: '#E4002B',
                            textShadow: '2px 2px 0px white',
                            fontWeight: 'bold',
                            fontFamily: 'Rubyck',
                            fontSize: '100px',
                        }}
                    >
                        SERVICIOS
                    </h1>
                </MKBox>
            </Grid>



           <Container>

               <Grid container spacing={2}>
                   <Grid item xs={12} sm={6} >
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 0, lg: 3 },
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,

                  }} >
                  <MKTypography data-aos="fade-right" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             1.Creación de un perfil psicodeportivo con el objetivo de determinar las fortalezas y oportunidades en cada atleta.
                      </MKTypography>
              </Card>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 3 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }} >
                  <MKTypography data-aos="fade-right" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             2.Creación de perfil psicodeportivo sin intervención para equipos, federaciones y clubes deportivos
                  </MKTypography>
              </Card>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 3 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),

                      boxShadow: ({ boxShadows: { xxxl } }) => xxxl,
                  }} >
                  <MKTypography data-aos="fade-right" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             3.Entrenamiento de la atención y la concentración
                  </MKTypography>
      </Card>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 3 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }} >
                  <MKTypography data-aos="fade-right" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             4.Entrenamiento de la reacción ante estímulos
                  </MKTypography>
    </Card>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 3 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }} >
                  <MKTypography data-aos="fade-right" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             5.Entrenamiento de la visualización
                  </MKTypography>
</Card>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 3 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }} >
                  <MKTypography data-aos="fade-right" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             6.Establecimiento de metas
                  </MKTypography>
</Card>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 3 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                      marginBottom: "200px"
                  }} >
                  <MKTypography data-aos="fade-right" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             7.Entrenamiento de la zona de desempeño óptimo
                  </MKTypography>
</Card>

          </Grid>

                   <Grid item xs={12} sm={6}>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 10 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }} >
                  <MKTypography data-aos="fade-left" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             8.Charlas
                  </MKTypography>
    </Card>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 10 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }} >
                  <MKTypography data-aos="fade-left" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             9.Talleres
                  </MKTypography>
              </Card>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 3 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }} >
                  <MKTypography data-aos="fade-left" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             10.Programación Neurolingüística (PNL)
                  </MKTypography>
    </Card>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 3 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }} >
                  <MKTypography data-aos="fade-left" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
             11.Coaching ontologico
                  </MKTypography>
    </Card>
                   <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 3 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }} >
                       <MKTypography data-aos="fade-left" data-aos-delay="100"
                           variant="h3"
                           fontWeight="medium"
                           textAlign="center"
                           color="white"
                       >
                      12.Evaluación de firmas
                       </MKTypography>
              </Card>
              <Card
                  sx={{
                      p: 2,
                      mx: { xs: 2, lg: 3 },
                      mt: 1,
                      mb: 4,
                      backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba('#D10303', 0.8),
                      backdropFilter: "saturate(200%) blur(30px)",
                      boxShadow: ({ boxShadows: { xxl } }) => xxl,
                  }} >
                  <MKTypography data-aos="fade-left" data-aos-delay="100"
                      variant="h3"
                      fontWeight="medium"
                      textAlign="center"
                      color="white"
                  >
                      13.Evaluación de firmas con intervención
                  </MKTypography>
    </Card>



             </Grid>
               </Grid>

             </Container>

        <MKBox pt={2} px={0} mt={0}>
            <DefaultFooter/>
        </MKBox>
    </>

  );


}

export default Presentation;
