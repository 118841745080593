/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import ContactUS from "pages/Presentation/sections/ContactUS";
import Download from "pages/Presentation/sections/Download";

// Presentation page components

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/Logo_PERFYLES.png";
import profesionales from "assets/images/profesionales.jpg";
import NavHeader from "../../../NavHeaderIndex";
import bgAboutus from "../../../assets/images/aboutusBG.jpg";


function Presentation() {
  return (
    <>
        <NavHeader/>
      <DefaultNavbar routes={routes} />
        <Grid container item justifyContent="center" mx="auto">
            <MKBox
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '500px', // Altura del banner
                    width: '100%',
                    backgroundImage: `url(${profesionales})`, // Ruta de la imagen de fondo
                    backgroundSize: 'cover', // Ajusta el tamaño de la imagen al contenedor
                    backgroundPosition: "50% 28%",
                }}
            >
                <h1
                    style={{
                        textAlign: 'center',
                        paddingBottom: '20px',
                        paddingTop: '60px',
                        color: '#E4002B',
                        textShadow: '2px 2px 0px white',
                        fontWeight: 'bold',
                        fontFamily: 'Rubyck',
                        fontSize: '100px',
                    }}
                >
                    PROFESIONALES
                </h1>
            </MKBox>
        </Grid>

          <Card
              sx={{
                  p: 2,
                  mx: { xs: 2, lg: 3 },
                  mt: 1,
                  mb: 4,
                  backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                  backdropFilter: "saturate(200%) blur(30px)",
                  boxShadow: ({ boxShadows: { xxl } }) => xxl,
              }}
          >

           <Container>
               <MKTypography
                   variant="h3"
                   color="black"
                   textAlign="center"
                   px={{ xs: 3, lg: 12 }}
                   mt={1}
               >
          <Grid container item xs={12} lg={17} justifyContent="center" mx="auto">
             DR. JOVANNY MONTERO, PhD
             </Grid>
        </MKTypography>
              <MKTypography
                  variant="h4"
                  color="warning"
                  textAlign="center"
                  px={{ xs: 3, lg: 12 }}
                  mt={1}
              >
             <Grid container item xs={12} lg={17} py={3} justifyContent="center" mx="auto">
             PSICOLÓGO DEPORTIVO
             </Grid>
             <Grid container item xs={12} lg={17} py={3} justifyContent="center" mx="auto">
             CEO E.S.PERFILES
             </Grid>

             </MKTypography>
           </Container>
             <Grid container item xs={12} lg={17} py={3} justifyContent="center" mx="auto">
                 <MKTypography
                     variant="h4"
                     color="dark"
                     textAlign="justify"
                     px={{ xs: 3, lg: 12 }}
                     mt={1}
                 >
             Como atleta de alto rendimiento en el baloncesto siempre estuve motivado por entender mis necesidades mentales como atletas y de paso la de mis compañeros.  Con el paso del tiempo entre a estudiar psicología clínica lo cual me permitió conocer las bases del comportamiento humano y sus motivaciones, durante los primeros 10 años de ejercicio profesional en el área de psicología clínica.  En conjunto con la practica clínica la preparación académica me llevo a desarrollar diferentes áreas, pero todas relacionadas con el comportamiento humano como diplomados, especialidad, maestría y finalmente un doctorado en psicología del deporte.  Lo que me ha permitido trabajar con atletas de alto rendimiento en casi todos los deportes de conjunto, de balón, individual y de raqueta, así como con atletas de medio Ironman.
                     <br />
                     <br />
             Incursionar en un área deportiva no solo poco conocida en la RD, sino que, para muchos inexistente, se convirtió en un gran reto que hoy en día me siento orgulloso de haber superado las barreras y establecer la psicología deportiva como una realidad en nuestro país.
                     <br />
                     <br />
             Haber experimentado como deportistas el miedo al fallo, las metas difusas y la ansiedad me han permitido ayudar a los actuales talentos deportivos que, a pesar de ser más fuertes, más rápidos y con mayores destrezas físicas los factores mentales que afectan el desempeño deportivo siguen siendo los mismos.  Por tal motivo instituciones profesionales de renombre como St. Louis Cardinals, Boston Red Sox, Oakland Athletics, Minnesota Twins, L.A. Dodgers, Anaheim Angels, Arizona Diamondbakcs, Selección Nacional de Voleibol, Selección Nacional de futbol Femenina, Pantoja FC e instituciones con programa deportivos escolar como Secundaria Babeque, Saint Thomas School, Saint George School, además de atletas individuales como jugadores de grandes Ligas, de la NBA y atletas paralímpicos han confiado en desarrollar la dureza mental con el objetivo de tener un mayor rendimiento deportivo.
                     <br />
                     <br />
             Hoy en dia cuento con mas de 20 anos de experiencias como psicodeportologo, haber participado en congresos nacionales e internacionales en el area deportiva, docente universitario, coautor de libro, articulista y conferencista que me han permitido adquirir gran experiencia, asi como trabajar la dureza mental a través de un enfoque holistico basado en el desarrollo de la confianza, auto diálogo, establecimiento de metas, manejo de la ansiedad competitiva, el estrés precompetitivo, la atención, la concentración y el nivel de competitividad deportiva.
                 </MKTypography>
             </Grid>
      </Card>
          <Card
              sx={{
                  p: 2,
                  mx: { xs: 2, lg: 3 },
                  mt: 1,
                  mb: 4,
                  backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                  backdropFilter: "saturate(200%) blur(30px)",
                  boxShadow: ({ boxShadows: { xxl } }) => xxl,
              }}
          >

                <Container>
                    <MKTypography
                        variant="h3"
                        color="black"
                        textAlign="center"
                        px={{ xs: 3, lg: 12 }}
                        mt={1}
                    >
             <Grid container item xs={12} lg={17} py={3} justifyContent="center" mx="auto">
             <strong>Yahaira Brea  </strong>
             </Grid>
                    </MKTypography>
                    <MKTypography
                        variant="h4"
                        color="warning"
                        textAlign="center"
                        px={{ xs: 3, lg: 12 }}
                        mt={1}
                    >
             <Grid container item xs={12} lg={17} py={3} justifyContent="center" mx="auto">
             MERCADEO Y COMUNICACIÓN
             </Grid>
             <Grid container item xs={12} lg={17} py={3} justifyContent="center" mx="auto">
             Programador Neurolingüístico  Practitioner Certificado  y Coach Ontológico  
             </Grid>

             </MKTypography>
                </Container>

             <Grid container item xs={12} lg={17} py={3} justifyContent="center" mx="auto">
                 <MKTypography
                     variant="h4"
                     color="dark"
                     textAlign="justify"
                     px={{ xs: 3, lg: 12 }}
                     mt={1}
                 >
             En los últimos 15 años  he tenido la oportunidad de desarrollar proyectos que me han apasionado. Me formé en la licenciatura de mercadeo con el propósito de ser directora de mercadeo de importantes instituciones.  He ayudado a profesionales y empresas a aumentar su visibilidad y notoriedad de marca y especialmente a aumentar su participación en el mercado a través del desarrollo y  conversión  de sus productos.
                     <br />
                     <br />
             En 2009 me formé con la maestría en Marketing & Sales Management de Stetson University de La Florida logrando completar una formación previa con la maestría en Alta Gerencia mención Finanzas del INTEC en República Dominicana.  Hoy día he completado la certificación en Coaching Ontológico con el aval del IESE y PNL Practitioner de la INLP.  Aprendí Marketing Digital con la Universidad de NEBRIJA España y mi próxima aspiración académica es el doctorado en gestión de proyectos.
                     <br />
                     <br />
             He diseñado la carrera de Comunicación Social para el INTEC, fui docente de las universidades UNIBE, UNAPEC e INTEC en el área de Negocios en las asignaturas de investigación de mercados, estrategia de precios, comportamiento del consumidor y gerencia de marca para los niveles de postgrado y grado.
                     <br />
                     <br />
             He sido miembro honorífico del Instituto CONFUCIO, miembro del jurado de los Premios EFFIE en R.D., miembro del jurado de TOPBRANDS.
                     <br />
                     <br />
             Mi experiencia laboral como directivo de marketing ha sido en empresas de en el grupo medios de comunicación Multimedios del Caribe y la universidad INTEC. En el ámbito de mercadeo deportivo tuve la oportunidad de ser la directivo  de Major League Baseball y CDN canal 37 donde diseñé implementé planes de activación de marca para la cerveza PRESIDENTE, la telefónica CLARO y muchos otros clientes que invierten en MLB.
                     <br />
                     <br />
                     Mi nuevo proyecto directivo es la agencia de marketing Yahaira Brea para continuar desarrollando y aportando a las empresas.
                 </MKTypography>
             </Grid>

          </Card>
             


        <DefaultFooter content={footerRoutes} />

    </>
  );
}

export default Presentation;
