import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "axios";
import { CenterFocusStrong } from "@mui/icons-material";
import moment from 'moment';
import { routes_logout } from "routes";
import SessionTimeout from "pages/timeout";
import { createBrowserHistory } from "history";

// import { BrowserRouter, Routes, Route } from "react-router-dom";

//Componentes
import userService from "../../../services/user.service"
import { useUser } from '../../Context';

import Swal from 'sweetalert2';
import '../../SweetAlert.css'

function LlenarEncuesta() {

  const navigate = useNavigate();
  const { contextAthleteID,contextUserID,ContextUiat, contextTestName,contextAdminUserID,LoggedUserRole } = useUser();

  console.log(contextUserID);

  let test = "";
  let userID = 0;
  let athleteID = 0;
  let checkedControl = 0
  let unblock;

  //const url = new URL(window.location)
  test= contextTestName
  if(LoggedUserRole === 'USER')
  {
     userID=contextAdminUserID
     
   } 
  else{
    userID=contextUserID
    
  }
  athleteID = contextAthleteID
  console.log("ID de atleta", athleteID);
  //const userName = url.searchParams.get("userName")
 // const testID = contextTestID
  const uiat = ContextUiat
//const role = url.searchParams.get("no");
  
  

  const [preguntas, setPreguntas] =useState([]);
  const [testName, setTestName] =useState([]);
  const [answer, setAnswer] = useState (null);
  const [questionNo, setQuestionNo] = useState ([]);
  const [respuestas, setRespuestas] = useState([]);
  const [post, setPost] = useState([]);
  const [date, setDate] = useState("");
  const [posicionActual, setPosicionActual] = useState(0);
  const [respuestasActuales, setRespuestasActuales] = useState([]);
  //const [opciones, setOpciones] = useState([]);

  const preguntasRespondidas = respuestas.map((r) => r.item);
 
  let opciones = [];
  let guideText = "";

 
  //const history = createBrowserHistory();

  const allQuestions  = async () => 
  { 
     await userService.getAllQuestions(test)
    .then(response => setPreguntas(response.data))
    .catch(error => console.log(error));
  }

  switch(test)
  {
    case 'TCPRD':
      guideText = "Conteste, por favor, a cada una de las siguientes preguntas, indicando en qué medida se encuentra de acuerdo con ellas. Como podrá observar existen cinco opciones de respuesta. Elija la que desee, según se encuentre más o menos de acuerdo, marcando el lugar correspondiente." ;
    break;
    case 'CC-10':
      guideText= "A continuación, encontrara diez frases que indican vivencias que los deportistas pueden experimentar cuando compiten. Lea cada una de ellas con atención y decida hasta qué punto estas situacionesse aplican a ti cuando compites. No existen respuestas correctas o incorrectas. Recuerde,elije la opción que describa mejor como te sientes cuando compites.";
      break;
    case 'TCSAI':
      guideText = "Complete la siguiente escala en dos ocasiones distintas: durante un tiempo tranquilo antes de la práctica cuando esté bastante relajado, y durante una situación competitiva que sienta que es muy estresante. Si actualmente no está activo en la competencia, recuerde estas situaciones lo más claramente posible y anote sus respuestas. Las siguientes son varias declaraciones que los atletas utilizan para describir sus sentimientos antes de la competencia. Lea cada declaración y haga un círculo alrededor del número apropiado para indicar cómo se siente ahora, en este momento. No hay respuestas correctas o incorrectas. No pase demasiado tiempo en ninguna de las preguntas." ;
      break;
    case 'LOEHR':
      guideText = "Seleccione uno de los 5 espacios para cada uno de los ítems en la siguiente lista. Sea tan abierto y honesto como pueda con usted mismo y conteste cada ítem en cuanto se refiere a usted en el aquí y ahora." ;
      break;
    case 'MINBO':
      guideText = " El Tennis Mind Body Checklist (TMBC) está diseñado para ayudarte a que te comprendas mejor. Lee cada pregunta y contesta verdadero o falso colocando V o F en el espacio en blanco proporcionado. Si no estás seguro de una pregunta en particular, o te parece que no se ajusta a ti, contesta de manera que se aproxime al máximo a tu punto de vista o como responderías si se ajustase al tema. Asegúrate que no queden preguntas sin contestar." ;
      break;
  }

  useEffect(() => {
    Swal.fire({
      icon: 'warning',
      title: guideText,
      customClass:{
        title: 'swal2-title'
      }
      
  })

    allQuestions();
    setDate(moment().utcOffset('-04:00').format('yyyyMMDDHHmmss'));

    // window.addEventListener("beforeunload", handleWindowClose);
    // return () => {
    //   window.removeEventListener("beforeunload", handleWindowClose);
   // };

   //manejando alerta cuando el usuario intente salir de la pagina
 //  unblock = history.block('¿Estás seguro de que deseas abandonar esta página?');
 //  window.addEventListener('beforeunload', handleBeforeUnload);
  // return () => {
    // window.removeEventListener('beforeunload', handleBeforeUnload);
  //   unblock();}
/*     window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblock();
    };  */
  //

    },[] );

  // const handleWindowClose = (event) => {
  //
  //     event.preventDefault();
  //     event.returnValue = '';
  //     window.alert('¿Estás seguro de que deseas salir?');
  //
  // }

  // const noEntiendoChange = (e) => {

  //   e.preventDefault();
    

  //   if(!checkboxValue){
  //     setError("Favor validar que no haya respuestas NO ENTIENDO en el formulario")
  //     alert("Favor leer la pregunta nuevamente")
  //   }else{
  //     setError("")
  //     alert("CORRECTO")
  // }
    

  // };   

  switch(test){
    case 'TCPRD':
    opciones = [
      { id: 1, texto: "Totalmente en desacuerdo" },
      { id: 2, texto: "Bastante en desacuerdo" },
      { id: 3, texto: "Un poco de acuerdo" },
      { id: 4, texto: "Bastante de acuerdo" },
      { id: 5, texto: "Totalmente de acuerdo" }
    ];
    break;
    case 'CC-10':
      opciones = [
        { id: 1, texto: "Casi nunca" },
        { id: 2, texto: "Algunas veces" },
        { id: 3, texto: "A menudo" }
      ];
      break;
    case 'TCSAI':
      opciones = [
        { id: 1, texto: "De ningún forma" },
        { id: 2, texto: "Algo" },
        { id: 3, texto: "Moderadamente" },
        { id: 4, texto: "Mucho" }
      ];
      break;
    case 'LOEHR':
      opciones = [
        { id: 1, texto: "Casi Siempre" },
        { id: 2, texto: "A Menudo" },
        { id: 3, texto: "A Veces" },
        { id: 4, texto: "Pocas Veces" },
        { id: 5, texto: "Casi Nunca" }
      ];
      break;
    case 'MINBO':
      opciones = [
        { id: 1, texto: "Verdadero" },
        { id: 2, texto: "Falso" }
      ];
      break;
  }
  const handleRespuestaChange = (pregunta, respuesta) => {
    // Actualizar la variable de estado respuestasActuales
    setRespuestasActuales((prevRespuestas) => {
      const nuevasRespuestas = [...prevRespuestas];
      const index = nuevasRespuestas.findIndex((r) => r.itemNo === pregunta);
      if (index !== -1) {
        nuevasRespuestas[index] = {
          ...nuevasRespuestas[index],
          item: respuesta,
        };
      } else {
        nuevasRespuestas.push({
          itemNo: pregunta,
          item: respuesta,
          ui_at: uiat,
          userId: userID,
          athletesId: athleteID,
          testId: test,
          sysCreationDate: date,
          value: 0,
        });
      }
      return nuevasRespuestas;
    });
  };
// const handleRespuestaChange = (pregunta, respuesta) => {
//
//   const index = respuestas.findIndex((r) => r.itemNo === pregunta);
//
//   if (index !== -1) {
//   setRespuestas(prevRespuestas => {
//   const nuevasRespuestas = [...prevRespuestas]; // Crear una copia del array de respuestas
//   nuevasRespuestas[index] = { ...nuevasRespuestas[index], item: respuesta }; // Actualizar la respuesta existente
//   return nuevasRespuestas;
// });
//   }else{
//   // Almacenar la respuesta en el estado del componente
//   setRespuestas((prevRespuestas) => [  ...prevRespuestas,{
//
//      itemNo: pregunta,
//      item: respuesta,
//      ui_at: uiat,
//      userId:userID,
//      athletesId:athleteID,
//      testId:test,
//      sysCreationDate:date,
//      value:0
// }]);
// }
// };

const handleSubmit = () => {

  // userService.postAllAnswers(respuestasActuales)
   const preguntasSinResponder = preguntasActuales.filter(pregunta => !respuestasActuales.some(respuesta => respuesta.itemNo === pregunta.itemNo));
   // userService.postAllAnswers(null)
   console.log("Post Athlete ID", respuestasActuales);
   userService.postAllAnswers(respuestasActuales)
        .then(response => {
          //console.log(response);
          if (preguntasSinResponder.length === 0) 
            {  //validar que todas las preguntas hayan sido respondidas
           // setPosicionActual(posicionActual + 5);
              if (response.data.success) 
                { 
                    navigate('/Resumen')
                } 
              else
                {
                  Swal.fire
                  ({
                  icon: 'error',
                  title: 'Error',
                 // text: 'Error en la solicitud',
                  text: error.response.data.message
                  });
                }
          } else {
           // Mostrar mensaje de error al usuario
           Swal.fire({
            icon: 'error',
            title: 'Por favor, responde todas las preguntas antes de continuar.',
        });   
            //alert("Por favor, responde todas las preguntas antes de continuar.");
           }
    }).catch
      (
        error =>   
          {
             Swal.fire(
              {
                icon: 'error',
                title: 'Error',
                text: error.response.data.message,
              }
              );
          }
      );
     ;



};

// const handleTimeout = () => {
//   window.location = "/pages/authentication/sign-in"
// };


/*MANEJANDO 5 PREGUNTAS POR PAGINA*/
  const preguntasActuales = preguntas.slice(
      posicionActual,
      posicionActual + 5
  );
  const mostrarSiguiente = () => {

    const preguntasSinResponder = preguntasActuales.filter(pregunta => !respuestasActuales.some(respuesta => respuesta.itemNo === pregunta.itemNo));
    if (preguntasSinResponder.length === 0) {  //validar que todas las preguntas hayan sido respondidas
      setPosicionActual(posicionActual + 5);
    } else {
     // Mostrar mensaje de error al usuario
     Swal.fire({
      icon: 'error',
      title: 'Por favor, responde todas las preguntas antes de continuar.',
  });   
      //alert("Por favor, responde todas las preguntas antes de continuar.");
     }

  };

  const mostrarAnterior = () => {
    setPosicionActual((prevPosicionActual) =>
        Math.max(prevPosicionActual - 5, 0));
   // setRespuestas({} ); // Reiniciar las respuestas al cambiar de página

  };
  /**/

    return (
    <>
      {/* <DefaultNavbar routes={routes_logout}/> */}

      <MKBox px={1} width="100%" height="100%" mx="auto" position="absolute" zIndex={2}
             sx={{
               backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                   `${linearGradient(
                       rgba(gradients.dark.main, 0.6),
                       rgba(gradients.dark.state, 0.6)
                   )}, url(${bgImage})`,
               backgroundSize: "cover",
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               paddingTop: "60px"
             }}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={10} >
            <Card >

              <MKBox
                   variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={1}
                  mt={1}
                  p={0}
                  mb={1}

                  
                  textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>

                  {(() => {
                    let testName = ""
                    switch(test){
                      case 'TCPRD':
                        testName = 'CPRD' ;
                        break;
                      case 'CC-10':
                        testName = 'CC-10' ;
                        break;
                      case 'LOEHR':
                        testName = 'Inventario de Loehr' ;
                        break;
                      case 'MINBO':
                        testName = 'Mind-Body Test' ;
                        break;
                      case 'TCSAI':
                        testName = 'CSAI-2' ;
                        break;
                    }
                    return testName
                  })()}

                </MKTypography>
              </MKBox>

         

              <MKBox pt={4} pb={3} px={3} >
                <MKBox component="form" /* onSubmit={handleSubmit} */ role="form" >

                  <MKBox mb={2}>
                    <table >
                      <tbody>
                      <tr>
                        <td></td>


                           {opciones.map((opcion) => (

                               <td key={opcion.id} style={{ paddingLeft: '20px', textAlign: 'center'}}>
                                    <span >{opcion.texto}</span>
                               </td>

                               ))}
                      </tr>


                  {preguntasActuales.map((pregunta) => (

                    //<div key={pregunta.id}>
                      <tr key={pregunta.id} >
                      <td style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'left', paddingBottom:'25px',  }}>
                          <h3>{pregunta.itemNo + ". " + pregunta.itemString}</h3>
                        </div>
                      </td>

                        {opciones.map((opcion) => (
                        <td key={opcion.id} style={{ textAlign: 'center', alignItems: 'right'}}>
                               <label>
                                <input
                                   type="radio"
                                   name={pregunta.itemNo}
                                   value={opcion.id}
                                   checked={respuestasActuales.some(
                                       (r) => r.itemNo === pregunta.itemNo && r.item === opcion.id
                                   )}
                                   onChange={() => handleRespuestaChange(pregunta.itemNo,  opcion.id,   )}
                                   style={{transform: 'scale(2.5)'}}
                                        />
                                  <span style={{ marginLeft: '5px' }}></span>
                                </label>
                        </td>
                            ))}
                      </tr>

                    ))}</tbody></table>


                    <div style={{ display: "flex", justifyContent: "space-between", marginTop:"50px" }}>
                      <MKButton
                          variant="gradient"
                          color="info"
                          type="submit"
                          onClick={(event) => {event.preventDefault();mostrarSiguiente()}}
                          disabled={posicionActual + 5 >= preguntas.length}
                          style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px',display: (posicionActual + 5 >= preguntas.length) ? 'none' : 'inline-block' }}
                          size="large"
                      >
                        Siguiente
                      </MKButton>

                      <MKButton
                          variant="gradient"
                          color="info"
                          type="submit"
                          onClick={(event) => {event.preventDefault(); handleSubmit()    }}
                                disabled={posicionActual + 5 < preguntas.length}
                                style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px',display: (posicionActual + 5 < preguntas.length) ? 'none' : 'inline-block', }}
                                size="large"
                      >
                        Ver resumen

                      </MKButton>
                    </div>

                  </MKBox>

                  </MKBox>
                    </MKBox>

                   {/*  <SessionTimeout timeoutDuration={600000} onTimeout={handleTimeout} /> */}
            </Card>
          </Grid>
        </Grid>
     
      </MKBox>  
     
    </>
    
  );
}

export default LlenarEncuesta;
