/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import ContactUS from "pages/Presentation/sections/ContactUS";
import Download from "pages/Presentation/sections/Download";

// Presentation page components

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/Logo_PERFYLES.png";
import location from "assets/images/location.png";
import bgContacto from "../../../assets/images/contactoBG.jpg";
import NavHeader from "../../../NavHeaderIndex";
import bgServicios from "../../../assets/images/serviciosBG.jpg";


function Contacto() {
    return (
        <>
            <NavHeader/>
            <DefaultNavbar routes={routes} />

                <Grid container item justifyContent="center" mx="auto">
                    <MKBox
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '500px', // Altura del banner
                            width: '100%',
                            backgroundImage: `url(${bgContacto})`, // Ruta de la imagen de fondo
                            backgroundSize: 'cover', // Ajusta el tamaño de la imagen al contenedor
                            backgroundPosition: "50% 27%",
                            marginBottom: '80px',
                        }}
                    >
                        <h1
                            style={{
                                textAlign: 'center',
                                paddingBottom: '20px',
                                paddingTop: '60px',
                                color: '#E4002B',
                                textShadow: '2px 2px 0px white',
                                fontWeight: 'bold',
                                fontFamily: 'Rubyck',
                                fontSize: '100px',
                            }}
                        >
                            CONTACTO
                        </h1>
                    </MKBox>
                </Grid>

                <Grid container spacing={5} justifyContent="center" alignItems="center">

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '80px', }}>
                        <a href="https://goo.gl/maps/keNTva5r2Z4kqriq5" target="_blank" rel="noopener noreferrer">
                            <img src={location} alt="Location" style={{ width: 'auto', maxWidth: '900px' }} />
                        </a>
                    </div>
                    <Card item xs={2}  sx={{marginBottom: "200px", marginLeft:"100px"}}>

                        <FilledInfoCard
                            color="info"
                            icon="email"
                            title="Correo"
                            description="esperfiles@gmail.com"

                        />

                        <FilledInfoCard
                            color="info"
                            icon="phone"
                            title="Teléfonos"
                            description="809.750.0716 / 809.988.6210"

                        />

                        <FilledInfoCard
                            color="info"
                            icon="address"
                            title="Dirección"
                            description="Avenida Tiradentes #40 esquina Presidente González, Naco, edificio ESPIRIT "

                        />


                    </Card>

                </Grid>
                <DefaultFooter content={footerRoutes} />



        </>
    );
}

export default Contacto;
