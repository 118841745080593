import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes

import { routes_logout } from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "axios";
import moment from 'moment';
import SessionTimeout from "pages/timeout";

// import { BrowserRouter, Routes, Route } from "react-router-dom";

//Componentes
import userService from "../../../services/user.service";

import Swal from "sweetalert2";
import { useUser } from '../../Context';
import { valid } from "chroma-js";


function Evaluaciones() {


  const navigate = useNavigate();
  const { 
    contextUserID, 
    LoggedUserRole,
    contextAthleteID, 
    setContextTestID, 
    setContextUiat,
    contextFirstName,
    contextLastName,
    contextPassword,
    contextEmail,
    contextCedula,
    newUserRole,
    newUserID
   } = useUser();

  const athleteID = contextAthleteID
  const [testID, setTestID] = useState("")
  const [validation, setValidation] = useState("")    
  const [date, setDate] = useState("");
  const [uiat, setUiat] = useState("");
  const [userID, setUserID] = useState("");
  var remoteInd = "";
  var remoteUserId = ""

  
          /* LLENADO LOS VALORES PARA IDENTIFICAR UN ATLETA QUE PUEDE HACER LOGIN */
          if (newUserRole === "USER")
          {
            remoteInd = "Y"
            remoteUserId = newUserID
          }else {
            remoteInd = "N"
            remoteUserId = 0
          }
        /* */

        console.log(contextUserID);

 
  useEffect(() => {
      setDate(moment().utcOffset('-04:00').format('yyyyMMDDHHmmss'));
  },[] );
  


 // var date = moment().utcOffset('-04:00').format('yyyyMMDDHHmmss');

     const nextBTN = () => {      

       if( validation > 0){
       
        setContextTestID(testID);
        setContextUiat(uiat);
        navigate(`/evaluador/`+athleteID)
                
       }else{
          Swal.fire({
              icon: 'warning',
              title: 'Advertencia',
              text: 'Favor seleccionar al menos una prueba.',
          });
                   }
         }

         //console.log(date);

         const assginedTest = (test) =>{

          var ui_at = `TEST${contextUserID}${athleteID}${date.slice(0,8)}`;
          setUiat(ui_at)

             const assignedTest = {
                 "athleteId": athleteID,
                 "userId": contextUserID,
                 "testId": test,
                 "uiAt":ui_at,
                 "sysCreationDate": date,
                 "completeDate":null,
                 "completeInd": "N",
                 "remoteInd" : remoteInd,
                 "remoteUserId" : remoteUserId
             };

             
             userService.getAssignedTestID(athleteID)
                 .then(response => {

                         if (response.data.length > 0) {
                             console.log(response)
                            // console.log(test)
                            //console.log(response.data[1].sysCreationDate.slice(0,8))
                            //console.log(date.slice(0,8))

                              let found = false;

                              for (let i = 0; i < response.data.length; i++) {
                                  if (response.data[i].testId === test && response.data[i].sysCreationDate.slice(0,8) == date.slice(0,8)) {
                                      found = true;
                                      break;
                                  }
                              }

                              if (found) {
                                  Swal.fire({
                                      icon: 'error',
                                      title: 'Error',
                                      text: 'El test ya está registrado.',
                                  }).then((result) => {
                                      if (result.isConfirmed) {
                                        navigate(`/evaluador/`+athleteID)
                                      }
                                  });
                              }

                             else {
                                  console.log("Primer Else", assignedTest)
                                 userService.postAssginedTest(assignedTest)
                                     .then(response => {
                                         setTestID(response.data.id)
                                         setValidation(1)
                                     })
                                     .catch(error => {
                                         Swal.fire({
                                             icon: 'error',
                                             title: 'Favor reintentar, si el error persiste, contacta al administrador1',
                                             text: error.response.data.message,
                                         });
                                     });
                             }
                         }else {
                              //console.log("HOLA",assignedTest);
                              console.log("Segundo Else", assignedTest)
                             userService.postAssginedTest(assignedTest)
                                 .then(response => {
                                     setTestID(response.data.id)
                                     setValidation(1)
                                 })
                                 .catch(error => {
                                     Swal.fire({
                                         icon: 'error',
                                         title: 'Favor reintentar, si el error persiste, contacta al administrador2',
                                         text: error.response.data.message,
                                     });
                                 });
                         }
                     }

                 )

         }

           const handleChangeCPRD = async (e) => {
            const checked = e.target.checked
                  
            if(checked){

              assginedTest('TCPRD')
  
                } else{
                  
                  userService.deleteAssignedTest(testID)
                  setValidation(0)
                }
        
           };
 
         const handleChangeComp = async (e) => {

          const checked = e.target.checked

          if (checked){
            assginedTest('CC-10')

          }else {

              
              userService.deleteAssignedTest(testID)
            setValidation(0)
          }

        };
        const handleChangeIllinois = async (e) => {
          const checked = e.target.checked
          if (checked == true){

            assginedTest('TCSAI')

          }else {
              
              userService.deleteAssignedTest(testID)
            setValidation(0)
          }
        };
        const handleChangeLoehr = async (e) => {
          const checked = e.target.checked
          if (checked ){
            assginedTest('LOEHR')
              
          }else {
              
              userService.deleteAssignedTest(testID)
            setValidation(0)
          }
        };
        const handleChangeMBL = async (e) => {
          const checked = e.target.checked
          if (checked){

            assginedTest('MINBO')

            
          }else {
              
              userService.deleteAssignedTest(testID)
            setValidation(0)
          }
        };

        const handleTimeout = () => {
          navigate( "/pages/authentication/sign-in")
        };

    return (
    <>
       <DefaultNavbar routes={routes_logout}/> 
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6) 
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="70%" mx="auto" position="relative" zIndex={2} top={90}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
            <Card>
              <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
                mx={1}
                mt={1}
                p={0}
                mb={1}
                  
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Seleccionar una o mas pruebas
                </MKTypography>
              </MKBox>
          
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form"  >


                  <MKBox  variant="gradient"
                bgColor="white"
                borderRadius="lg"
                coloredShadow="info"
                mx={1}
                mt={1}
                p={1}
                mb={2}
                width="30%"
                px={1}
                container spacing={1}>
                      <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
                  <label >
                    <input
                      type="checkbox"
                      onChange={handleChangeCPRD}   />
                   CPRD
                  </label>

                    </Grid>
                    <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleChangeComp}
                    />
                      CC-10
                  </label>
                    </Grid>
                      <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleChangeIllinois}
                    />
                      CSAI-2
                  </label>
                      </Grid>
                      <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleChangeLoehr}
                    />
                      Inventario de Loehr
                  </label>
                      </Grid>
                      <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
                  <label >
                    <input
                      type="checkbox"
                      margin-left= "10px"
                      onChange={handleChangeMBL}
                    />
                      Mind-Body Test
                  </label>
                      </Grid>
                  </MKBox>
                    <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="dark"  
                      onClick={() => {
                        nextBTN();
                                  }} 
                      
                      
                      >
                       Siguiente
                       
                    </MKButton>
                    </MKBox>
                </MKBox>
                 {/* <SessionTimeout timeoutDuration={100000} onTimeout={handleTimeout} />  */}
              </MKBox>
            </Card>
          </Grid>
        </Grid>
     
      </MKBox>  
     
    </>
    
  );
}

export default Evaluaciones;
