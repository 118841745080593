/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================
*/

import { useEffect } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Material Kit 2 React themes
import theme from 'assets/theme';
import Presentation from 'layouts/pages/presentation';

// Material Kit 2 React routes
import routes from 'routes';

import Evaluador from 'layouts/pages/Evaluador'
import Administrador from 'layouts/pages/Administrador'
import AtletaExistente from 'layouts/pages/AtletaExistente'
import AtletaNuevo from 'layouts/pages/AtletaNuevo'
import CrearPerfil from 'layouts/pages/CrearPerfil'
import EvalComAtleta from 'layouts/pages/EvalComAtleta'
import EvalComDeporte from 'layouts/pages/EvalComDeporte'
import EvalComEquipo from 'layouts/pages/EvalComEquipo'
import EvalCompletadas from 'layouts/pages/EvalCompletadas'
import EvalIncompletas from 'layouts/pages/EvalIncompletas/evalIncompletas'
import Evaluar from 'layouts/pages/Evaluar'
import LlenarEncuesta from 'layouts/pages/LlenarEncuesta'
import ResumenEncuesta from 'layouts/pages/ResumenEncuesta';
import Evaluaciones from 'layouts/pages/Evaluaciones';
import { UserProvider } from 'pages/Context';
import AuthHeader from 'services/auth-header';
import SingIn from 'layouts/pages/authentication/sign-in';
import NoTest from 'layouts/pages/NoTests'

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserProvider>
      <Routes>
        {getRoutes(routes)}
        {/* <Route path='/presentation' element={<Presentation />} /> */}
        {/* <Route path='*' element={<Navigate to='/presentation' />} />  */}
        <Route path='*' element={<SingIn/>}/> 
        <Route path='/evaluador/:id' element={<Evaluador />} />
        <Route path='/administrador' element={<Administrador />} />
        <Route path='/atletaExistente' element={<AtletaExistente />} />
        <Route path='/atletaNuevo' element={<AtletaNuevo />} />
        <Route path='/crearPerfil' element={<CrearPerfil />} />
        <Route path='/evalComAtleta' element={<EvalComAtleta />} />
        <Route path='/evalComDeporte' element={<EvalComDeporte />} />
        <Route path='/evalComEquipo' element={<EvalComEquipo />} />
        <Route path='/evalCompletadas' element={<EvalCompletadas />} />
        <Route path='/evalIncompletas' element={<EvalIncompletas />} />
        <Route path='/evaluar' element={<Evaluar />} />
        <Route path='/llenarEncuesta' element={<LlenarEncuesta />} />
        <Route path='/Resumen' element={<ResumenEncuesta/>} />
        <Route path='/Evaluaciones' element={<Evaluaciones/>}/>
        <Route path='/AuthHeader' element={<AuthHeader/>}/>
        <Route path='/NoTest' element={<NoTest/>}/>

      </Routes>
      </UserProvider>
    </ThemeProvider>
  );
}
