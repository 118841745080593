import { useUser } from '../pages/Context';
import { useContext } from 'react';

export default function authHeader() {

  //const {contextToken} = useUser();
//const context = useContext(useUser);

  const user = JSON.parse(sessionStorage.getItem('user'));
  //console.log(user);

  if (user) {
    return { Authorization: 'Bearer ' + user}; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
}
