import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "axios";
import { CenterFocusStrong } from "@mui/icons-material";
import TextField from "assets/theme/components/form/textField";
import MKDatePicker from "components/MKDatePicker";

import { routes_logout } from "routes";
import SessionTimeout from "pages/timeout";
import Swal from "sweetalert2";
import moment from 'moment';

// import { BrowserRouter, Routes, Route } from "react-router-dom";

//Componentes
import userService from "../../../services/user.service"
import AuthService from "../../../services/auth.service";
import { useUser } from '../../Context';

function Evaluaciones() {

    const navigate = useNavigate();
    const { setContextTextName,setContextTestID,contextUserID,setContextUiat, LoggedUserRole,setContextAthleteID } = useUser();

    const userDataJSON = AuthService.getCurrentUser();
   
  //let uiat = ""
  
  const [athlete, setAthlete] = useState("");
  const [athleteTest, setAthleteTest] = useState("");
  const [datos, setDatos] =useState([]);
  const params = useParams();
  const [athleteID, setathleteID] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [evaluador, setEvaluador] = useState("");
  const [buttonText, setButtonText] = useState('Editar Datos');
  const [runUseEffect, setRunUseEffect] = useState(false);


 // const url = new URL(window.location)
  //const userID = url.searchParams.get("userID")
  // const userName = url.searchParams.get("userName")
  // const testID = url.searchParams.get("testID")
  // uiat = url.searchParams.get("uiat")
 // const role = url.searchParams.get("no");


  useEffect(() => {

 //   console.log(userID);
    async function fetchData(id){
        const evaluador = await userService.getActivateUser(contextUserID)
        .catch(error => 
            {
                    Swal.fire({
                    icon: 'error',
                    title: 'Error al realizar la solicitud, si el error persiste, contacta al administrador',
                    text: error.response.data.message,
                    });
            }
            )
     
        if(LoggedUserRole === "USER"){
            await userService.getPendingTests(id)
            .then
            (
                response => 
                {
                    if (response.data.message == "false")
                    {
                        Swal.fire({
                            icon: 'info',
                            title: 'Gracias por completar todas las pruebas, su usuario sera desactivado',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    //console.log("ID",id);
                                    //console.log("Usuario",evaluador)

                                    const postData =
                                    {
                                        "id": evaluador.data.id,
                                        "firstName": evaluador.data.firstName,
                                        "lastName": evaluador.data.lastName,
                                        "email": evaluador.data.email,
                                        "pin": evaluador.data.pin,
                                        "sysCreationDate": evaluador.data.sysCreationDate,
                                        "role": evaluador.data.role,
                                        "activeInd": "N",
                                    }
                                    userService.patchUser(postData, evaluador.data.id)
                                    navigate( "/pages/authentication/sign-in");

                                }
                            });
                    }
                }
            )
            const uniqueAthlete = await userService.getUniqueAthlete(id)
            const athleteTests = await userService.getAssignedTestID(id)
                .then(response => setDatos(response.data) 
                )
                .catch(error =>
                        {
                            Swal.fire({
                            icon: 'error',
                            title: 'Error al realizar la solicitud, si el error persiste, contacta al administrador',
                            text: error.response.data.message,
                            });
                        }
      
                       );                       
                setAthlete(uniqueAthlete.data)
                
                setEvaluador(evaluador.data)
                
                
        }
        else{
            const uniqueAthlete = await userService.getUniqueAthlete(id)
            const athleteTests = await userService.getAssignedTestID(id)
                .then(response => setDatos(response.data))
                .catch(error =>
                        {
                            Swal.fire({
                            icon: 'error',
                            title: 'Error al realizar la solicitud, si el error persiste, contacta al administrador',
                            text: error.response.data.message,
                            });
                        }
                      );
                setAthlete(uniqueAthlete.data)
                
                setEvaluador(evaluador.data)
            }
    }
   
    setathleteID(params.id)
    
    
    fetchData(params.id);

  },[runUseEffect] )
  

  console.log("Datos",datos);
  var nacimiento = athlete.birthDate;

  if (nacimiento !== undefined){
      nacimiento = nacimiento.toString().slice(0,8);

      var year = nacimiento.slice(0, 4);
      var month = parseInt(nacimiento.slice(4, 6)) - 1; // Restar 1 al mes
      var day = nacimiento.slice(6, 8);
      var date = new Date(year, month, day);
       var formattedDate = date.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    }).replace(/\//g, "-");
  }

  
//   const handleTimeout = () => {
//     navigate("/pages/authentication/sign-in")
//   };

  const evaluarAtleta = () => {
   
   navigate('/evaluar');
  }

  const pruebasCompletadas = () => {

    if(LoggedUserRole !== "ADM"){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Acceso denegado, favor contactar al administrador',
      });

    }else{
    
    navigate('/evalCompletadas')
    }
  }

  const asignarPrueba = () => {
    setContextAthleteID(athleteID);
   
   navigate('/evaluaciones')
  }

  const eliminarOpcion = (opcionId) => {

    if(LoggedUserRole !== "USER"){
    const nuevasOpciones = datos.filter((opcion) => opcion.id !== opcionId);
    
        userService.deleteAssignedTest(opcionId)
        .then( response => {
              if(response.status === 200){
              setDatos(nuevasOpciones)
            }
            else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al realizar la solicitud, si el error persiste, contacta al administrador',
              });
            }
        })
    }else{
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Usted no tiene permisos para realizar esta acción',
          });
    }
  };

    /***********************EDITAR EL TEXTO *******************************/
    const [editMode, setEditMode] = useState(false);

    const [editableAthlete, setEditableAthlete] = useState({
        firstName: '',
        lastName: '',
        age: '',
        birthDate: '',
        sport: '',
        club: '',
        position: '',
        playerCategory: '',
        maxCategory: '',
        othersCategories: '',
        categoryTime: '',
        sysCreationDate: '',
        users: {
            id: contextUserID
        }
    });

    const handleEditMode = () => {
        setEditMode(!editMode);

        if (editMode) {
            setEditMode(false);
            setButtonText('Editar Datos');
        } else {
            setEditMode(true);
            setButtonText('Atras');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditableAthlete((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const athletePost = {
        id:athlete.id,
        firstName: editableAthlete.firstName.trim() !== '' ? editableAthlete.firstName : athlete.firstName,
        lastName: editableAthlete.lastName.trim() !== '' ? editableAthlete.lastName: athlete.lastName,
        age: editableAthlete.age.trim() !== '' ? editableAthlete.age: athlete.age,
        birthDate: editableAthlete.birthDate.trim() !== '' ? moment(editableAthlete.birthDate).format('yyyyMMDDHHmmss') : athlete.birthDate,
        sport: editableAthlete.sport.trim() !== '' ? editableAthlete.sport: athlete.sport,
        club: editableAthlete.club.trim() !== '' ? editableAthlete.club: athlete.club,
        position: editableAthlete.position.trim() !== '' ? editableAthlete.position: athlete.position,
        playerCategory: editableAthlete.playerCategory.trim() !== '' ? editableAthlete.playerCategory: athlete.playerCategory,
        maxCategory: editableAthlete.maxCategory.trim() !== '' ? editableAthlete.maxCategory: athlete.maxCategory,
        othersCategories: editableAthlete.othersCategories.trim() !== '' ? editableAthlete.othersCategories: athlete.othersCategories,
        categoryTime: editableAthlete.categoryTime.trim() !== '' ? editableAthlete.categoryTime: athlete.categoryTime,
        sysCreationDate: editableAthlete.sysCreationDate.trim() !== '' ? editableAthlete.sysCreationDate: athlete.sysCreationDate,
        users: {
            id: contextUserID
        }
    };
    const handleEditConfirmation = async () => {
        // Crear el objeto "athlete" con los datos editados

        try {
            const response = await userService.postUpdateAthlete(athleteID,athletePost);
                    
            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Correcto',
                    text: 'Campo editado con exito',
                }).then((result) => {
                    if (result.isConfirmed) {
                        setEditMode(false);
                        if(runUseEffect){
                            setRunUseEffect(false)
                            } 
                            else 
                            {
                                setRunUseEffect(true)
                            }
                    }
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Favor reintentar, si el error persiste, contacta al administrador',
                });
            }
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ocurrió un error al enviar los datos. Favor reintentar más tarde',
            });
        }
    };
    /*****************************************************/
  return (
    <>
     <DefaultNavbar routes={routes_logout}/>
      <MKBox px={1} width="100%" height="100%" mx="auto" position="absolute" zIndex={2}
             sx={{
                 backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                     `${linearGradient(
                         rgba(gradients.dark.main, 0.6),
                         rgba(gradients.dark.state, 0.6)
                     )}, url(${bgImage})`,
                 backgroundSize: "cover",
                 backgroundPosition: "center",
                 backgroundRepeat: "no-repeat",
                 paddingTop: "60px"
             }}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
            <Card>
              <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
                mx={1}
                mt={1}
                p={0}
                mb={1}
                  
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Datos personales
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                  <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                    <b>
                  Nombres:
                  </b>
                </p>
                {/*    <p style={{ color: '#000000',fontFamily: 'Rubyck', fontSize:'20px' }}>*/}
                {/*   {athlete.firstName}*/}
                {/*</p>*/}
                      {editMode ? (
                          <>
                              <input
                                  type="text"
                                  name="firstName"
                                  value={editableAthlete.firstName}
                                  onChange={handleInputChange}
                              />

                          </>
                      ) : (
                          <p style={{ color: '#000000', fontFamily: 'Rubyck', fontSize: '20px' }}>
                              {athlete.firstName}
                          </p>
                      )}
                  </MKBox>
                  <MKBox mb={2}>
                    <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                      <b>
                  Apellidos:
                      </b>
                </p>
                      {editMode ? (
                          <>
                              <input
                                  type="text"
                                  name="lastName"
                                  value={editableAthlete.lastName}
                                  onChange={handleInputChange}
                              />
                          </>
                      ) : (
                          <p style={{ color: '#000000', fontFamily: 'Rubyck', fontSize: '20px' }}>
                              {athlete.lastName}
                          </p>
                      )}

                  </MKBox>
                  <MKBox mb={2}>
                    <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                      <b>
                  Edad:
                      </b>
                </p>
                      {editMode ? (
                          <>
                              <input
                                  type="text"
                                  name="age"
                                  value={editableAthlete.age}
                                  onChange={handleInputChange}
                              />
                          </>
                      ) : (
                          <p style={{ color: '#000000', fontFamily: 'Rubyck', fontSize: '20px' }}>
                              {athlete.age}
                          </p>
                      )}

                  </MKBox>
                  <MKBox mb={2}>
                    <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                      <b>
                  Fecha de nacimiento:
                      </b>
                </p>
                      {editMode ? (
                          <>
                          <p>YYYYMMDD</p>
                              <input
                                  type="text"
                                  name="birthDate"
                                  value={editableAthlete.birthDate}
                                  onChange={handleInputChange}
                              />
                          </>
                      ) : (
                          <p style={{ color: '#000000', fontFamily: 'Rubyck', fontSize: '20px' }}>
                              {formattedDate}
                          </p>
                      )}
                  </MKBox>
                  <MKBox mb={2}>
                    <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                      <b>
                  ¿Qué deporte practicas?:
                      </b>
                </p>
                      {editMode ? (
                          <>
                              <input
                                  type="text"
                                  name="sport"
                                  value={editableAthlete.sport}
                                  onChange={handleInputChange}
                              />
                          </>
                      ) : (
                          <p style={{ color: '#000000', fontFamily: 'Rubyck', fontSize: '20px' }}>
                              {athlete.sport}
                          </p>
                      )}
                  </MKBox>
                  <MKBox mb={2}>
                    <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                      <b>
                  ¿Para qué club/equipo u organización compites?:
                      </b>
                </p>
                      {editMode ? (
                          <>
                              <input
                                  type="text"
                                  name="club"
                                  value={editableAthlete.club}
                                  onChange={handleInputChange}
                              />
                          </>
                      ) : (
                          <p style={{ color: '#000000', fontFamily: 'Rubyck', fontSize: '20px' }}>
                              {athlete.club}
                          </p>
                      )}

                  </MKBox>
                  <MKBox mb={2}>
                    <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                      <b>
                  Posición en la que compites según el deporte que practicas:
                      </b>
                </p>
                      {editMode ? (
                          <>
                              <input
                                  type="text"
                                  name="position"
                                  value={editableAthlete.position}
                                  onChange={handleInputChange}
                              />
                          </>
                      ) : (
                          <p style={{ color: '#000000', fontFamily: 'Rubyck', fontSize: '20px' }}>
                              {athlete.position}
                          </p>
                      )}

                  </MKBox>
                  <MKBox mb={2}>
                    <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                      <b>
                  Categoría en la que compites actualmente:
                      </b>
                </p>
                      {editMode ? (
                          <>
                              <input
                                  type="text"
                                  name="playerCategory"
                                  value={editableAthlete.playerCategory}
                                  onChange={handleInputChange}
                              />
                          </>
                      ) : (
                          <p style={{ color: '#000000', fontFamily: 'Rubyck', fontSize: '20px' }}>
                              {athlete.playerCategory}
                          </p>
                      )}

                  </MKBox>
                  <MKBox mb={2}>
                    <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                      <b>
                  Máxima categoría en la que has competido:
                      </b>
                </p>
                      {editMode ? (
                          <>
                              <input
                                  type="text"
                                  name="maxCategory"
                                  value={editableAthlete.maxCategory}
                                  onChange={handleInputChange}
                              />
                          </>
                      ) : (
                          <p style={{ color: '#000000', fontFamily: 'Rubyck', fontSize: '20px' }}>
                              {athlete.maxCategory}
                          </p>
                      )}

                  </MKBox>
                  <MKBox mb={2}>
                    <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                      <b>
                  Categorías en la que has competido y años de competencia o jugados:
                      </b>
                </p>
                      {editMode ? (
                          <>
                              <input
                                  type="text"
                                  name="othersCategories"
                                  value={editableAthlete.othersCategories}
                                  onChange={handleInputChange}
                              />
                              <MKBox mt={4} mb={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <MKButton variant="gradient" color="info" onClick={handleEditConfirmation}>
                                  Confirmar edición
                              </MKButton>
                              </MKBox>
                          </>
                      ) : (
                          <p style={{ color: '#000000', fontFamily: 'Rubyck', fontSize: '20px' }}>
                              {athlete.othersCategories}
                          </p>
                      )}

                      {(() => {
                          if (LoggedUserRole === 'ADM') {
                              return (

                      <MKBox mt={4} mb={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <MKButton
                          variant="gradient"
                          color="info"
                          onClick={handleEditMode}
                          style={{ marginRight: '20px' }}
                      >
                          {buttonText}
                      </MKButton>
                      </MKBox>
                              );
                          }
                          return null; // Si no cumple la condición, devolvemos null o un componente vacío
                      })()}

                  </MKBox>

                    <MKBox mb={2}>
                        <p style={{ color: '#E4002B',fontFamily: 'Rubyck', fontSize:'30px' }}>
                            <b>
                                Evaluador
                            </b>
                        </p>
                    <p style={{ color: '#000000',fontFamily: 'Rubyck', fontSize:'20px' }}>
                        {evaluador.firstName} {evaluador.lastName}
                    </p>
                </MKBox>

                  <MKBox mt={4} mb={1} style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {(() => {
                          if (LoggedUserRole === 'ADM') {
                              return (
                                  <MKButton variant="gradient" color="info" onClick={pruebasCompletadas} style={{ marginRight: '20px' }}>
                                      Ver pruebas completadas
                                  </MKButton>
                              );
                          }
                          return null; // Si no cumple la condición, devolvemos null o un componente vacío
                      })()}
                      {(() => {
                          if (LoggedUserRole === 'ADM' || LoggedUserRole === 'MOD') {
                            return (
                      <MKButton variant="gradient" color="info" onClick={evaluarAtleta}  style={{ marginRight: '20px'}} >
                         Evaluar nuevo atleta
                      </MKButton>
         );
        }
        return null; // Si no cumple la condición, devolvemos null o un componente vacío
    })()}
     {(() => {
                          if (LoggedUserRole === 'ADM' || LoggedUserRole === 'MOD') {
                            return (
                    <MKButton variant="gradient" color="info" onClick={asignarPrueba}
                    >
                      Asignar prueba

                    </MKButton>
                     );
                    }
                    return null; // Si no cumple la condición, devolvemos null o un componente vacío
                     })()}
                    </MKBox>
                  <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
                mx={1}
                mt={1}
                p={0}
                mb={1}
                  
                textAlign="center"
              >
               
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Elegir prueba
                </MKTypography>
              </MKBox>
                    <MKBox mt={4} mb={3} xl={10}>
                        {datos.map((objeto) => (
                            <div key={objeto.id}>
                                {(() => {
                                    let testName = "";

                                    switch (objeto.testId) {
                                        case 'TCPRD':
                                            testName = 'CPRD Asignada:';
                                            break;
                                        case 'CC-10':
                                            testName = 'CC-10 Asignada:';
                                            break;
                                        case 'LOEHR':
                                            testName = 'Inventario de Loehr Asignada:';
                                            break;
                                        case 'MINBO':
                                            testName = 'Mind-Body Test Asignada:';
                                            break;
                                        case 'TCSAI':
                                            testName = 'CSAI-2 Asignada:';
                                            break;
                                    }

                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                            <p style={{ marginRight: '10px' }}>{testName + objeto.sysCreationDate.toString().slice(0, 8)}</p>
                                            {(() => {
                                            if (objeto.completeInd === 'N') {
                                                return(
                                                    <>
                                            <button
                                                style={{ fontSize: '16px', padding: '8px 16px' }}
                                                onClick={(event) => {
                                                    event.preventDefault();

                                                    if (objeto.completeInd === 'Y') {
                                                        Swal.fire({
                                                            icon: 'warning',
                                                            title: 'Advertencia',
                                                            text: 'Esta prueba ya fue completada',
                                                        });
                                                    } else {
                                                      
                                                       setContextTextName(objeto.testId);
                                                       setContextUiat(objeto.uiAt)
                                                       setContextTestID(objeto.id);
                                                       setContextAthleteID(objeto.athleteId); //ENVIANDO EL ATHLETE ID AL CONTEXT PARA QUE SEA UTILIZADO EN EL ENVIO DE LA DATA CUANDO SE HACE EL POST AL BACKEND, DE NO HACER ESTO, SE ENVIA NULL.
                                                       navigate('/llenarEncuesta');
                                                    }
                                                }}
                                            >
                                                Iniciar
                                            </button>
                                                    </>
                                                )
                                        }
                                            })()}
                                            {(() => {
                                                if (objeto.completeInd === 'N') {
                                                    return (
                                                        <>
                                                            <p style={{ color: 'red', marginRight: '10px', marginLeft:'30px' }}>Pendiente</p>
                                                            <button
                                                                style={{ fontSize: '16px', padding: '8px 16px' }}
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    event.stopPropagation();
                                                                    eliminarOpcion(objeto.id);
                                                                }}
                                                            >
                                                                Eliminar
                                                            </button>
                                                        </>
                                                    );
                                                } else {
                                                    return <p style={{ color: 'green', marginRight: '10px' }}>Completado</p>;
                                                }
                                            })()}
                                        </div>
                                    );
                                })()}
                            </div>
                        ))}
                    </MKBox>
    
                    </MKBox>        
                    {/* <SessionTimeout timeoutDuration={100000} onTimeout={handleTimeout} /> */}
              </MKBox>
              
            </Card>
          </Grid>
        </Grid>
     
      </MKBox>
     
    </>
    
  );
}

export default Evaluaciones;
