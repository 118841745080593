import React, { createContext, useContext, useState } from 'react';
import { useAsyncError } from 'react-router-dom';

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children  }) {
  const [contextUserID, setContextUserID] = useState(null);
  const [contextUserName, setContextUsername] = useState(null);
  const [LoggedUserRole, setLoggedUserRole] = useState(null);
  const [contextToken, setContextToken] = useState(null);
  const [contextTestID, setContextTestID] = useState(null);
  const [contextTestName, setContextTextName] = useState(null);
  const [ContextUiat, setContextUiat] = useState(null);
  const [contextFirstName, setContextFirstName] = useState(null);
  const [contextLastName, setContextLastName] = useState(null);
  const [contextPassword, setContextPassword] = useState(null);
  const [contextEmail, setContextEmail] = useState(null);
  const [contextCedula, setContextCedula] = useState(null);
  const [contextCreateDate, setContextCreateDate] = useState(null);
  const [newUserRole, setNewUserRole] = useState(null);
  const [contextActInd, contextSetActInd]= useState(null);
  const [contextAthleteID, setContextAthleteID] = useState(null);
  const [newUserID, setNewUserID] = useState(null);
  const [contextAdminUserID, setContextAdminUserID] = useState(null);
  

  return (
    <UserContext.Provider value={{
       contextUserID, setContextUserID, 
       contextUserName, setContextUsername, 
       LoggedUserRole, setLoggedUserRole, 
       contextToken,  setContextToken, 
       contextTestID, setContextTestID, 
       ContextUiat, setContextUiat,
       contextFirstName, setContextFirstName,
       contextLastName, setContextLastName,
       contextPassword, setContextPassword,
       contextEmail, setContextEmail,
       contextCedula, setContextCedula,
       contextCreateDate,  setContextCreateDate,
       newUserRole, setNewUserRole,
       contextActInd, contextSetActInd,
       contextAthleteID, setContextAthleteID,
       newUserID, setNewUserID,
       contextTestName, setContextTextName,
       contextAdminUserID, setContextAdminUserID,
        }}>
      {children}
    </UserContext.Provider>
  );
}


