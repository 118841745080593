import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import  {routes_logout} from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "axios";
import AuthService from "../../../services/auth.service";

// import { BrowserRouter, Routes, Route } from "react-router-dom";

import userService from "../../../services/user.service"
import { useUser } from '../../Context';

function Evaluar() {

  const navigate = useNavigate();

  //const { userID } = useUser();
  //console.log(userID);

  //const url = new URL(window.location)
  //const userID = url.searchParams.get("userID")
  //const userName = url.searchParams.get("userName")
  //const role = url.searchParams.get("no")

  /// new method ////
  const currentUser = AuthService.getCurrentUser();


  const [athlete, setAthlete] = useState([]);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [findAthlete, setFindAthlete] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {setContextTestID, setContextUiat, setContextUserID} = useUser();
  
  // const allAthletes = async (state) => {
  //   const peticion = await userService.getAllAthletes()
  //   state(peticion.data)
  // }

  useEffect(() => {

    setIsLoading(true);

    async function fetchData(){
      const atletas = await userService.getAllAthletes();
      const prueba = await userService.getAssignedTests();

      console.log("ATLETA",atletas.data)

      setIsLoading(false);
      setAthlete(atletas.data);
      setEvaluaciones(prueba.data);
      setContextTestID(prueba.data.testId);
      setContextUiat(prueba.data.uiAt);

    }

    fetchData();

  },[] );

  const evaluacionesCompletadas = evaluaciones.filter(evaluacion => evaluacion.completeInd === 'N');

  //console.log(evaluacionesCompletadas);

  const searchChange = event => {
    setFindAthlete(event.target.value.toLowerCase());
  };

  const handleBack = () => {
   
   navigate('/administrador');
  }

  const manejarKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // Puedes agregar tu lógica personalizada aquí si es necesario
    }
  };
  

  return (
    <>
      <DefaultNavbar routes={routes_logout}/>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6) 
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="70%" mx="auto" position="relative" zIndex={2} top={90}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={1}
                mt={1}
                p={0}
                mb={1}

                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Evaluaciones Pendientes
                </MKTypography>
              </MKBox>

              <MKBox pt={4} pb={3} px={3}>

                <MKBox component="form" role="form">

                <MKBox mb={2}>
                  <MKInput type="filter" label="NOMBRE, APELLIDO, DEPORTE, CLUB" fullWidth
                      onChange={searchChange}
                      onKeyDown={manejarKeyDown}
                  />

                </MKBox>

                <MKBox mb={2}>
  <div style={{ textAlign: "left" }}>
        {isLoading ? (
      <p>Cargando...</p>
      
    ) : (
      
      evaluacionesCompletadas !== null && findAthlete !== '' ? (
        
        athlete
        
          .filter((usuario) => {
            return (
                                        
              usuario.firstName.toLowerCase().includes(findAthlete) ||
              usuario.lastName.toLowerCase().includes(findAthlete) ||
              usuario.sport.toLowerCase().includes(findAthlete) ||
              usuario.club.toLowerCase().includes(findAthlete)
            
            );
            
          })
          .map((usuario) => {
            // Buscamos la evaluación correspondiente al usuario
            const evaluacionUsuario = evaluacionesCompletadas.find(
              (evaluacion) => evaluacion.athleteId === usuario.id
            );
            // Si hay una evaluación completada para el usuario, la mostramos
            if (evaluacionUsuario) {
              return (
                <li key={usuario.id} style={{ marginBottom: "10px" }}>
                  <b>Nombre:</b>{usuario.firstName.toUpperCase()}&nbsp;
                  <b>Apellido:</b>{usuario.lastName.toUpperCase()}&nbsp;
                  <b>Deporte:</b>{usuario.sport.toUpperCase()}&nbsp;
                  <b>Club:</b>{usuario.club.toUpperCase()}&nbsp;&nbsp;&nbsp;&nbsp;
                  {/* <a href={`/evaluador/${usuario.id}?userID=${userID}&userName=${userName}&testID=${evaluacionUsuario.testId}&uiat=${evaluacionUsuario.uiAt}&no=${role}`}>VER</a> */}
                  <a   href="#" // You can use a placeholder href
                      onClick={(e) => {
                      e.preventDefault(); // Prevent the default link behavior
                      setContextUserID(evaluacionUsuario.userId);
                      navigate(`/evaluador/${usuario.id}`);
                       }}>VER</a>
                </li>
              );
            } else {
              return null;
            }
          })
      ) : (
        "Favor buscar el atleta deseado en el recuadro de arriba"
      )
    )}
  </div>
</MKBox>

                                          
                  <MKBox mb={2}>
                    <MKButton variant="gradient" color="info"
                              onClick={handleBack}>
                      Volver
                    </MKButton>
                  </MKBox>

                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>

      </MKBox>



    </>

  );

}

export default Evaluar;
