import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes

import { routes_logout } from "routes";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "axios";
import moment from 'moment';
import Swal from 'sweetalert2';
import SessionTimeout from "pages/timeout";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

import DateTimePicker from 'react-datetime-picker';


// import { BrowserRouter, Routes, Route } from "react-router-dom";

//Componentes
import userService from "../../../services/user.service";
import AuthService from "../../../services/auth.service";

import { useUser } from '../../Context';
import { valid } from "chroma-js";


function AtletaNuevo() {

  const navigate = useNavigate();

  
  //const [firstname, lastname, password, email, isValid, cedula, users] = props.datos;

  const {LoggedUserRole, contextUserID} = useUser();
 

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [sport, setSport] = useState("");
  const [club, setClub] = useState("");
  const [position, setPosition] = useState("");
  const [category, setCategory] = useState("");
  const [maxCategory, setMaxCategory] = useState("");
  const [othersCategories, setOthersCategories] = useState("");
  const [categoryTime, setCategoryTime] = useState("");
  const [athleteID, setathleteID] = useState("");

  const [isNameEmpty, setIsNameEmpty] = useState(true);
  const [isLastNameEmpty, setIsLastNameEmpty] = useState(true);
  const [isAgeEmpty, setIsAgeEmpty] = useState(true);
  const [isBirthDateEmpty, setIsBirthDateEmpty] = useState(true);
  const [isSportEmpty, setIsSportEmpty] = useState(true);
  const [isClubEmpty, setIsClubEmpty] = useState(true);

  const [dbValidation, setDbValidation] = useState(true);

  const {
    setContextAthleteID, 
    setNewUserID, 
    newUserRole,
    contextFirstName,
    contextLastName,
    contextPassword,
    contextEmail,
    contextCedula,
  } = useUser();

  //const url = new URL(window.location)
  //const userID = url.searchParams.get("userID")
 // const userName = url.searchParams.get("userName")
  const role = LoggedUserRole;

  const customShortWeekday = ['D', 'L', 'M', 'Mi', 'J', 'V', 'S'];
      
  const nameChange = event => {
    setName(event.target.value);
    setIsNameEmpty(event.target.value.trim() === '');
  };

  const lastNameChange = event => {
    setLastName(event.target.value);
    setIsLastNameEmpty(event.target.value.trim() === '');
  };

  const ageChange = event => {
    setAge(event.target.value);
    setIsAgeEmpty(event.target.value.trim() === '');
  };

  // const birthDateChange = date => {
  //   setBirthDate(date);
  //   setIsBirthDateEmpty(date === '');
  // };

  const sportChange = event => {
    setSport(event.target.value);
    setIsSportEmpty(event.target.value.trim() === '');
  };

  const clubChange = event => {
    setClub(event.target.value);
    setIsClubEmpty(event.target.value.trim() === '')
  };

  const positionChange = event => {
      setPosition(event.target.value);
  };

  const categoryChange = event => {
    setCategory(event.target.value);
  };

  const maxCategoryChange = event => {
    setMaxCategory(event.target.value);
  };

  const othersCategoriesChange = event => {
    setOthersCategories(event.target.value);
  };

  const categoryTimeChange = event =>{
    setCategoryTime(event.target.value);
  }

  const [dayValue, setDayValue] = useState('');
  const [monthValue, setMonthValue] = useState('');
  const [yearValue, setYearValue] = useState('');
  //const [dateString, setDateString] = useState('');

  const today = Date.now();

  // console.log("dayValue",dayValue);
  // console.log("monthValue",monthValue);
  // console.log("yearValue",yearValue);
    
  var date = moment().utcOffset('-04:00').format('yyyyMMDDHHmmss');

  // if(dayValue > 0 && monthValue > 0 && yearValue > 0){
   const dateString =`${yearValue.padStart(4,'19')}${monthValue.padStart(2, '0')}${dayValue.padStart(2, '0')}000000`;
// }

  if(position == "" || position == null){
    setPosition("vacio")
      }

  if(category == "" || category == null){
    setCategory("vacio")
     }

  if(maxCategory == "" || maxCategory == null){
    setMaxCategory("vacio")
     }

  if(othersCategories == "" || othersCategories == null){
    setOthersCategories("vacio")
     }
  
  if(categoryTime == "" || categoryTime == null){
    setCategoryTime("0")
  }

  
  
  const athlete = {
    "firstName": name ,
    "lastName":lastName,
    "age":age,
    // "birthDate":moment(birthDate).format('yyyyMMDDHHmmss'),
    "birthDate":dateString,
    "sport":sport,
    "club":club,
    "position":position,
    "playerCategory":category,
    "maxCategory":maxCategory,
    "othersCategories":othersCategories,
    "categoryTime":categoryTime,
    "sysCreationDate": date ,
    "users":
    {
      "id": contextUserID
    } 
  };


  useEffect(() => {
      if (athleteID > 0) 
    {
        // console.log(athleteID)
        setContextAthleteID(athleteID)  
      //AGREGAR VALIDACION AQUI PARA PASAR DE PAGINA SI NO ARROJA ERROR AL MOMENTO DE INSERTAR EL ATLETA EN LA DB
      if(dbValidation){
        navigate('/evaluaciones');
      }
    }
  }, [athleteID]);

  const nextBTN = async () => {
    //console.log("dateString",dateString);
    // if (name !== "" && lastName !== "" && age > 0 && birthDate !== "" && sport !== "" && club !== "")
    if (name !== "" && lastName !== "" && age > 0 && dayValue !== "" && monthValue !== "" && yearValue !== "" && sport !== "" && club !== "")
    {
      //Willis Arias 20230114
      //Agregado para evitar que al no llenar todos los campos la primera vez y despues llenar el campo o campos que quedaron vacios, permita moverse de pantalla
      setDbValidation(true);
      //Fin
      await userService.postAthlete(athlete)
      .then(response => 
        {
          if (response.status === 201) 
          {
            setathleteID(response.data.id)
            /*********CREANDO EL PERFIL DE LOGIN SI EL USUARIO ES UN ATLETA********** */
             if (newUserRole === "USER") 
             {
                AuthService.register
                (
                  contextFirstName,
                  contextLastName,
                  contextPassword,
                  contextEmail,
                  contextCedula,
                  moment().utcOffset('-04:00').format('yyyyMMDDHHmmss'),
                  newUserRole, 
                  "Y"
                ).
                then
                ( 
                  response => 
                  {
                    setNewUserID(response.data.userID);
                    if (response.data.success == true)
                    {
                      console.log('OK')
                    }
                    else
                    {
                      setDbValidation(false);
                      Swal.fire
                      ({
                        icon: 'error',
                        title: 'Error',
                        text: 'Error registrando usuario para el nuevo atleta'
                      });   
                    }
                  }).catch
                  (
                    error => 
                    {
                      // console.log(error)
                      setDbValidation(false);
                      Swal.fire
                      ({
                        icon: 'error',
                        title: 'Proceso no completado, favor volver a intentar. Si el error persiste, contactar al administrador',
                        text: error.response.data.message,
                      });
                    }
                  )
              }
                /****FIN*******/
          }
            // } else {
            //   Swal.fire({
            //     icon: 'error',
            //     title: 'Error',
            //     text: 'Proceso no completado, favor volver a intentar. Si el error persiste, contactar al administrador',
            //   });
            // }

          })
          .catch(error => {
            console.log(error);
          if(error.response.data.message.includes('already exists in the database')){
              setDbValidation(false);
              Swal.fire({
              icon: 'error',
              title: 'Atleta ya existe, favor validar',
              text: error.response.data.message,
            })
          }else{
              setDbValidation(false);
              Swal.fire({
              icon: 'error',
              title: 'Proceso no completado, favor volver a intentar. Si el error persiste, contactar al administrador',
              text: error.response.data.message,
              });
          }
          })
 
    }else {
        setDbValidation(false);
        Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Favor llenar todos los campos mandatorios',
      });
    }

  }


        //  const handleTimeout = () => {
        //   window.location = "/pages/authentication/sign-in"
        // };

  function handleInput(event) {
    var input = event.target;
    input.value = input.value.charAt(0).toUpperCase() + input.value.slice(1);
  }

  const handleDayValue = (event) => {
    const value =event.target.value
    setDayValue(value)   
  };

  const handleMonthValue = (event) => {
    const value = event.target.value
    setMonthValue(value);
  };

  const handleYearValue = (event) => {   
    const value = event.target.value
      setYearValue(value);
  };



    return (
    <>
       {/* <DefaultNavbar routes={routes_logout}/>  */}

      <MKBox px={1} width="100%" height="100%" mx="auto" position="relative" zIndex={2} top={0}
             sx={{
               backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                   `${linearGradient(
                       rgba(gradients.dark.main, 0.6),
                       rgba(gradients.dark.state, 0.6)
                   )}, url(${bgImage})`,
               backgroundSize: "cover",
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
             }}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={8} >
            <Card>
              <MKBox
                variant="gradient"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
                mx={1}
                mt={1}
                p={0}
                mb={1}
                  
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Datos Atleta
                </MKTypography>
                <MKTypography variant="h7" fontWeight="medium" color="error" mt={1} justifyContent="right">
                  Los campos con * son obligatorios
                </MKTypography>
              </MKBox>
          
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form"  >

                  <MKBox mb={2}>
                    <MKInput type="text" label="Nombre"   fullWidth   onChange={nameChange}   onInput={handleInput} />
                      {isNameEmpty? (
                      <MKTypography variant="h7"  color="error" mt={1} justifyContent="right">
                        *
                      </MKTypography>
                      ) : null
                      }
                  </MKBox>

                  <MKBox mb={2}>
                    <MKInput type="text" label="Apellidos" fullWidth  onChange={lastNameChange}  onInput={handleInput} />
                      {isLastNameEmpty? (
                        <MKTypography variant="h7"  color="error" mt={1} justifyContent="right">
                            *
                        </MKTypography>
                      ) : null
                      }
                  </MKBox>

                <MKBox mb={2}>
                    <MKInput type="number" label="Edad" fullWidth  onChange={ageChange} />
                    {isAgeEmpty? (
                        <MKTypography variant="h7"  color="error" mt={1} justifyContent="right">
                            *
                        </MKTypography>
                    ) : null
                    }
                </MKBox>

    
                   <MKBox mb={2}>
                    <MKTypography variant="h7" color="dark" mt={1} justifyContent="right">
                      Fecha de nacimiento: &nbsp;
                    </MKTypography>
                    {/* <Calendar onChange={birthDateChange}  //calendarType="ISO 8601"      locale="es-ES"   formatShortWeekday={(locale, date) => customShortWeekday[date.getDay()]}                    /> */}

                    <div className="dropdown">
                    <label>Dia</label> <input type="number" min="01" max = "31"  onChange={handleDayValue}  style={{ marginRight: '10px' }} />                    
                    <label>Mes</label> <input type="number" min="01" max = "12"  onChange={handleMonthValue} style={{ marginRight: '10px' }} />
                    <label>Año</label> <input type="number" min="1900" onChange={handleYearValue} />
                    </div>

                    {isBirthDateEmpty ? (
                        <MKTypography variant="h7" color="error" mt={1} justifyContent="right">
                          *
                        </MKTypography>
                    ) : null}
                  </MKBox>




                  <MKBox mb={2}>
                    <MKInput type="text" label="Que deporte practicas" fullWidth  onChange={sportChange}  onInput={handleInput} />
                     {isSportEmpty? (
                             <MKTypography variant="h7"  color="error" mt={1} justifyContent="right">
                               *
                             </MKTypography>
                     ) : null
                     }
                </MKBox>

                <MKBox mb={3}>
                    <MKInput type="text" label="Para que club/equipo u Organización Compites" fullWidth  onChange={clubChange}  onInput={handleInput}/>
                    {isClubEmpty? (
                        <MKTypography variant="h7"  color="error" mt={1} justifyContent="right">
                            *
                        </MKTypography>
                    ) : null
                    }
                </MKBox>

                  <MKBox mb={2}>
                    <MKInput type="text" label="Posición en la que Compites según el deporte que practicas" fullWidth
                    onChange={positionChange}
                    onInput={handleInput}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type="text" label="Categoría en la que compites actualmente" fullWidth 
                    //onChange={(val) => setPassword(val)}
                    onChange={categoryChange}
                    onInput={handleInput}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type="text" label="Máxima categoría en la que has Competido" fullWidth 
                    //onChange={(val) => setPassword(val)}
                    onChange={maxCategoryChange}
                    onInput={handleInput}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type="text" label="Categorías en la que has competido" fullWidth 
                    //onChange={(val) => setPassword(val)}
                    onChange={othersCategoriesChange}
                    onInput={handleInput}
                                />
                  </MKBox>

                  <MKBox mb={2}>
                    <MKInput type="number" label="Años de competencia o jugados" fullWidth 
                    //onChange={(val) => setPassword(val)}
                    onChange={categoryTimeChange}
                                />
                  </MKBox>
                  {/*<MKBox mt={4} mb={1}>*/}
                  {/*  <MKButton variant="gradient" color="dark"  */}
                  {/*    onClick={() => {*/}
                  {/*      validarBTN();*/}
                  {/*                }}       >*/}
                  {/*     Validar*/}
                  {/*    */}
                  {/*  </MKButton>*/}
                  {/*  </MKBox>*/}
 
                    <MKBox mt={4} mb={1}>
                    <MKButton variant="gradient" color="dark"  
                      onClick={() => {
                        nextBTN();
                                  }} 
                      
                      
                      >
                       Siguiente
                       
                    </MKButton>
                    </MKBox>
                </MKBox>
                {/* <SessionTimeout timeoutDuration={100000} onTimeout={handleTimeout} /> */}
              </MKBox>
            </Card>
          </Grid>
        </Grid>
     
      </MKBox>  
     
    </>
    
  );
}

export default AtletaNuevo;
