/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import perfylesText from "../../../assets/images/perfylesText.png";

function DefaultFooter() {
  return (
      <footer>
          <div className="footer-container" style={{ backgroundColor: '#151414', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <a href="/" >
              <img
                  className="logo"
                  src={perfylesText}
                  style={{
                      width: '100%',
                      maxWidth: '300px',
                      height: 'auto',
                      marginBottom: '0px',
                  }}
                  alt="Logo de Perfyles"
              />
              </a>
              <nav className="footer-links" style={{ display: 'flex', justifyContent: 'center' }}>
                  <ul style={{ listStyle: 'none', padding: '0' }}>
                      <li>
                          <a
                              href="/Servicios"
                              style={{
                                  fontFamily: 'Rubyck',
                                  color: 'white',
                                  textAlign: 'center',
                                  display: 'block',
                                  lineHeight: '1',
                                  textDecoration: 'none',
                                  padding: '10px',
                                  margin: '10px',

                              }}
                          >
                              Servicios
                          </a>
                      </li>
                      <li>
                          <a
                              href="/Contacto"
                              style={{
                                  fontFamily: 'Rubyck',
                                  color: 'white',
                                  textAlign: 'center',
                                  display: 'block',
                                  lineHeight: '1',
                                  textDecoration: 'none',
                                  padding: '10px',
                                  margin: '10px',

                              }}
                          >
                              Contacto
                          </a>
                      </li>
                      <li>
                          <a
                              href="/SobreNosotros"
                              style={{
                                  fontFamily: 'Rubyck',
                                  color: 'white',
                                  textAlign: 'center',
                                  display: 'block',
                                  lineHeight: '1',
                                  textDecoration: 'none',
                                  padding: '10px',
                                  margin: '10px',

                              }}
                          >
                              Sobre Nosotros
                          </a>
                      </li>
                      <li>
                          <a
                              href="/Profesionales"
                              style={{
                                  fontFamily: 'Rubyck',
                                  color: 'white',
                                  textAlign: 'center',
                                  display: 'block',
                                  lineHeight: '1',
                                  textDecoration: 'none',
                                  padding: '10px',
                                  margin: '10px',

                              }}
                          >
                              Profesionales
                          </a>
                      </li>
                  </ul>
              </nav>
          </div>
      </footer>



  );
}

export default DefaultFooter;
