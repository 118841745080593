import axios from 'axios';
import authHeader from './auth-header';

//import { useContext } from 'react';

//const API_URL = 'http://localhost:8088/api/'; //LOCAL
const API_URL = 'http://www.perfyles.com:8088/api/'; //PROD
//const API_URL = 'http://159.203.72.253:8088/api/';


class UserService {

    getPublicContent() {
        return axios.get(API_URL + 'all');
    }

    /******  new code for notificatios GB 20240107 */
    getAllNewNotifications() {
        return axios.get(API_URL + `notifications/getPending`, {headers: authHeader()});
    }
    updateAllnewNotificatiosAsComplete(){
        return axios.put(API_URL + 'notifications/setCompleted',  {headers: authHeader()})
    }
    /******  new code for notificatios GB 20240107 */


    //GET METHODS
    getAssignedTesteByStatus(completeInd) {
        return axios.get(API_URL + `joins/getAssignedTestsByStatus/${completeInd}`, {headers: authHeader()});
    }

    getAllAthletes() {
        return axios.get(API_URL + 'athletes', {headers: authHeader()});
    }

    getAssignedTests() {
        return axios.get(API_URL + 'assignedTest', {headers: authHeader()})
    }

    getAthletesTests() {
        return axios.get(API_URL + 'athletesTests', {headers: authHeader()})
    }

    getUniqueAthlete(ID) {
        return axios.get(API_URL + `athletes/${ID}`, {headers: authHeader()})
    }

    getAssignedTestID(ID) {
        return axios.get(API_URL + `assignedTest/${ID}`, {headers: authHeader()})
    }

    getAllQuestions(test) {
        return axios.get(API_URL + `items/${test}`, {headers: authHeader()})
    }

    getQuestionsResume(user, athlete, test, uiat) {
        return axios.get(API_URL + `athletesTests/runSP_UPDATE_TEST_VALUES?V_ATHLETES_ID=${athlete}&V_TEST_ID=${test}&V_UI_AT=${uiat}`, {headers: authHeader()})
        /*CAMBIADO PARA QUE NO ENVIE EL USER ID YA QUE ENTRA EN CONFLICTO ENTRE EL USUARIO QUE CREA LA PRUEBA
        Y EL USUARIO QUE LA LLENA, YA QUE NO NECESARIAMENTE QUIEN LA CREA SEA EL QUE LA LLENE. Willis Arias 20231107*/
        //return axios.get(API_URL + `athletesTests/runSP_UPDATE_TEST_VALUES?V_USER_ID=${user}&V_ATHLETES_ID=${athlete}&V_TEST_ID=${test}&V_UI_AT=${uiat}`, {headers: authHeader()})
    }

    getQuestionsData(uiat, test) {
        return axios.get(API_URL + `athletesTests/athletesTestsWithItemsByUiat/${uiat}/testid/${test}`, {headers: authHeader()})
    }

    getUsers() {
       // return axios.get(API_URL + 'users', {headers: {Authorization: 'Bearer ' + token}})
       return axios.get(API_URL + 'users', {headers: authHeader()})
    }

     getActivateUser(userID) {
         return axios.get(API_URL + `users/${userID}`, {headers: authHeader()})
     }

    getRemoteUser(userID){
        return axios.get(API_URL + `assignedTest/remote/${userID}`, {headers: authHeader()})
    }

    // getReport(uiat){
    //   return axios.get(API_URL + `Reports/getReportByUiAt?ui_at=${uiat}`)
    // }

    /// new path for the word Report /// by GB
    getReport(uiat) {
        return axios.get(API_URL + `Reports/getword/${uiat}`, {
                headers: authHeader(),
                responseType: 'blob',
            }
        )
    }

    getVisualizeReport(uiat) {
        return axios.get(API_URL + `Reports/getbytes/${uiat}`, {
                headers: authHeader(),
                //responseType: 'blob',
            }
        )
    }

    getNoTestAssigned() 
    {
        return axios.get(API_URL + "athletes/noTestAssig", {headers: authHeader(),})
    }

    getPendingTests(athleteID) 
    {
        return axios.get(API_URL + `assignedTest/${athleteID}/hasPendingTests`, {headers: authHeader(),})
    }

    getAtheleteAndTest()
    {
        return axios.get(API_URL + "athletes/withAT", {headers: authHeader(),})
    }

    getAssignedTestByID(testID){
        return axios.get(API_URL + `assignedTest/tblid/${testID}`, {headers: authHeader(),})

    }

    //POST METHODS
    postAthlete(dataPost) {
        return axios.post(API_URL + 'athletes/save', dataPost, {headers: authHeader()})
    }

    postAssginedTest(dataPost) {
        return axios.post(API_URL + 'assignedTest/save', dataPost, {headers: authHeader()})
    }

    postAllAnswers(answers) {
        return axios.post(API_URL + 'athletesTests/saveAll', answers, {headers: authHeader()})
    }

    postUpdateAthlete(ID, dataPost){
        return axios.put(API_URL + `athletes/${ID}`, dataPost, {headers: authHeader()})
    }

    //PATCH METHODS
    patchUser(postData, userID) {
       // console.log(userID);
        return axios.patch(API_URL + `users/${userID}`, postData, {headers: authHeader()})
    }


    //DELETE METHODS
    deleteAssignedTest(dataDelete) {
        return axios.delete(API_URL + `assignedTest/${dataDelete}`, {headers: authHeader()})
    }
}

export default new UserService();
