/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import ContactUS from "pages/Presentation/sections/ContactUS";
import Download from "pages/Presentation/sections/Download";

// Presentation page components

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/Logo_PERFYLES.png";
import bgAboutus from "../../../assets/images/aboutusBG.jpg";
import NavHeader from "../../../NavHeaderIndex";
import bgContacto from "../../../assets/images/contactoBG.jpg";


function Presentation() {
  return (
    <>
        <NavHeader/>
      <DefaultNavbar routes={routes} />
        <Grid container item justifyContent="center" mx="auto">
            <MKBox
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '500px', // Altura del banner
                    width: '100%',
                    backgroundImage: `url(${bgAboutus})`, // Ruta de la imagen de fondo
                    backgroundSize: 'cover', // Ajusta el tamaño de la imagen al contenedor
                    backgroundPosition: "50% 22%",
                }}
            >
                <h1
                    style={{
                        textAlign: 'center',
                        paddingBottom: '20px',
                        paddingTop: '60px',
                        color: '#E4002B',
                        textShadow: '2px 2px 0px white',
                        fontWeight: 'bold',
                        fontFamily: 'Rubyck',
                        fontSize: '100px',
                    }}
                >
                    SOBRE NOSOTROS
                </h1>
            </MKBox>
        </Grid>
        <Card
            sx={{

                backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                height: "100%",
                width: "100%",
                paddingBottom: '80px',
            }}
        >
                <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="flag"
                title="MISIÓN"
                
              />
              <Grid item xs={10} lg={8} justifyContent="center" mx="auto">
                <b>Nuestra misión es proporcionar en el ámbito deportivo las herramientas que los atletas necesitan para alcanzar el máximo nivel de rendimiento físico-mental.</b>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="VISIÓN"
                
              />
              <Grid item xs={10} lg={8} justifyContent="center" mx="auto">
              <b> Convirtiéndonos en la opción más viable de servicios psicodeportivos en el desarrollo de competencias individuales o de equipos en la República Dominicana.</b>
              </Grid>
               </Grid>
            <Grid item xs={12} lg={4} >
              <FilledInfoCard
                color="info"
                icon="apps"
                title="VALORES"
              />
              <Grid item xs={10} lg={7} justifyContent="center" style={{textAlign:'left', marginLeft:'95px'}}>
              <b>Confidencialidad</b>
              </Grid>
              <Grid item xs={10} lg={7} justifyContent="center" style={{textAlign:'left', marginLeft:'95px'}}>
                  <b>Integridad</b>
              </Grid>
                <Grid item xs={10} lg={7} justifyContent="center" style={{textAlign:'left', marginLeft:'95px'}}>
                    <b>Compromiso</b>
                </Grid>
                <Grid item xs={10} lg={7} justifyContent="center" style={{textAlign:'left', marginLeft:'95px'}}>
                    <b>Innovación</b>
                </Grid>
                <Grid item xs={10} lg={7} justifyContent="center" style={{textAlign:'left', marginLeft:'95px'}}>
                        <b>Mejora continua</b>
                </Grid>
            </Grid>
          </Grid>
     

         
      </Card>
            <DefaultFooter content={footerRoutes} />


    </>
  );
}

export default Presentation;
