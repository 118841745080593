/**
 =========================================================
 * Material Kit 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useEffect} from "react";
import { Navigate, useNavigate } from "react-router-dom";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

// Material Kit 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Kit 2 React page layout routes
import {routes_logout} from "routes";


// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import axios from "axios";

import {saveAs} from "file-saver";
import MKButton from "../../../components/MKButton";

import userService from "../../../services/user.service"
import link from "../../../assets/theme/components/link";
import ReactDOM from 'react-dom';
import ReactFileReader from 'react-file-reader';
import {constrainedMemory} from "process";
import Swal from 'sweetalert2';
import { useUser } from '../../Context';



function EvalCompletadas() {

  const navigate = useNavigate();
  const { contextToken } = useUser();

    const [athlete, setAthlete] = useState([]);
    const [evaluaciones, setEvaluaciones] = useState([]);
    const [findAthlete, setFindAthlete] = useState("");
    const [athletesAndTests, setAthletesAndTests] = useState([]);

   // const url = new URL(window.location)
   // const userID = url.searchParams.get("userID")
   // const userName = url.searchParams.get("userName")
    const [isLoading, setIsLoading] = useState(false);
  //  const role = url.searchParams.get("no");


    // test lat /// GB
   // const completeInd = 'Y';



    useEffect((   ) => {

        setIsLoading(true);

        async function fetchData(  ) {

            const atletas = await userService.getAllAthletes();
            const prueba = await userService.getAthletesTests();
            setIsLoading(false);
            setAthlete(atletas.data);
            setEvaluaciones(prueba.data);

            // new code for tests list by status or completeInd

            
            await  userService.getAtheleteAndTest()
            .then(
                response => {
                 //   console.log("Respuesta",response.data[0].assignedTests.length);
                    setAthletesAndTests(response.data);

                //     for (let i = 0; i < response.data.length; i++) {
                //         setAthletesTests(response.data[i]);
                //     }
                //const newData = Array.isArray(response.data) ? response.data : [];
                //setAthletesTests(prevAthletesTests => [...prevAthletesTests, ...newData]);
                 }
                
            )
        

             //console.log(assignedTestsByStatusN);

        }
       fetchData();


    }, []);

    //console.log(athletesTests);
    const completedTests = new Set();
    const atletasYEvaluaciones = athletesAndTests.map(atleta => {
        const usuario = atleta.id;
        if (atleta.assignedTests.length > 0) {
            completedTests.add(usuario);
            return { ...atleta };
        } else {
            return null;
        }
    }).filter(result => result !== null);
    
    //console.log("AyE", atletasYEvaluaciones[0].assignedTests[0].testId);
    

    // const processedAthletes = new Set(); // conjunto para almacenar IDs de atletas procesados
    // const usuariosYEvaluaciones = evaluaciones.map(evaluacion => {
    //     const usuario = athlete.find(usuario => evaluacion.athletesId === usuario.id);
    //     if (usuario && !processedAthletes.has(usuario.id)) { // si el atleta existe y no ha sido procesado antes
    //         processedAthletes.add(usuario.id); // lo agregamos al conjunto de atletas procesados
    //         return {...usuario, evaluacion};
    //     } else {
    //         return null; // si el atleta ya ha sido procesado o no existe, retornamos null
    //     }
    // }).filter(result => result !== null); // eliminamos los resultados nulos (atletas repetidos o no encontrados)

    const searchChange = event => {
        setFindAthlete(event.target.value.toLowerCase());
    };

    // console.log(usuariosYEvaluaciones)

    //// new method for download new report from backend. /// by GB
    const generarDocumento = async (uiat) => {
        try {
            setIsLoading(true);
            const response = await userService.getReport(uiat);
            const filename = getFilenameFromResponse(response);
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setIsLoading(false);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error al descargar el archivo.',
            }).then((result) => {
                if (result.isConfirmed) {
                    // Refrescar la página
                    navigate('/evalCompletadas', {replace:true})
                }
            });
            setIsLoading(false);
        }
    };


    /// method for get the name of the file and remove assci characters
    const getFilenameFromResponse = (response) => {
        const contentDisposition = response.headers['content-disposition'];
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            const encodedFilename = matches[1].replace(/['"]/g, '');
            const decodedFilename = decodeURIComponent(encodedFilename);
            return decodedFilename;
        }
        return 'archivo.docx'; // Nombre predeterminado si no se encuentra en el encabezado
    };

    const WordDocumentPreview = ({ document }) => {
        if (!document) {
            return <div>Loading...</div>;
        }
        return <div dangerouslySetInnerHTML={{ __html: document }} />;
    };

    // const previsualizarDocumento = async (uiat) => {
    //     try {
    //         const response = await userService.getVisualizeReport(uiat);
    //         const filename = getFilenameFromResponse(response);
    //         const blob = new Blob([response.data], { type: 'application/octet-stream' });
    //         const url = window.URL.createObjectURL(blob);

    //         const reader = new ReactFileReader();
    //         reader.read(url);
    //         reader.onload = (event) => {
    //             const document = reader.result; // Obtener el contenido del documento aquí
    //             ReactDOM.render(<WordDocumentPreview document={document} />, document.body);
    //         };
    //     } catch (error) {
    //         console.error('Error al descargar el archivo:', error);
    //     }
    // };

    // const previsualizarDocumento =  (uiat) => {
    //     try {
         
    //         window.open("https://docs.google.com/document/d/1yDGpxQ6Wiej-iVydn7P4isjOBKTrz9sd/edit?usp=drive_link&ouid=107053226415689019348&rtpof=true&sd=true", "_blank");

    //       } catch (error) {
    //         console.error('Error al descargar el archivo:', error.message);
    //         // En caso de error, puedes manejarlo de alguna manera (por ejemplo, mostrar un mensaje de error en la interfaz de usuario).
    //         return null; // Esto evita que se produzca un error en tiempo de ejecución si el componente no se renderiza correctamente.
    //       }

    // };
    
    


    const previsualizarDocumento = async (uiat) => {
    
    
        const response = await userService.getVisualizeReport(uiat)
    
        const html = response.data.message;
            
        // Abre una nueva ventana del navegador
        const ventanaNueva = window.open();
    
        // Crea un nuevo elemento div y establece su contenido HTML
        const contenido = ventanaNueva.document.createElement('div');
        contenido.innerHTML = html;
    
        // Agrega el contenido al cuerpo del documento de la ventana
        ventanaNueva.document.body.appendChild(contenido);
    
    
    }

    const handleBack = () => {
        
        navigate('/administrador');
    }

    const manejarKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          // Puedes agregar tu lógica personalizada aquí si es necesario
        }
      };



      const groupTestsByDate = (tests) => {
        const groupedTests = {};
        tests.forEach((test) => {
          const date = test.sysCreationDate.slice(0, 8); // Tomar solo la parte de la fecha (sin la hora)
          if (!groupedTests[date]) {
            groupedTests[date] = [];
          }
          groupedTests[date].push(test);
        });
        return groupedTests;
      };

      //const groupedTests = groupTestsByDate(athletesTests.assignedTests);

    return (
        <>
            <DefaultNavbar routes={routes_logout}/>
            <MKBox
                position="absolute"
                top={0}
                left={0}
                zIndex={1}
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <MKBox px={1} width="100%" height="70%" mx="auto" position="relative" zIndex={2} top={90}>
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                    <Grid item xs={11} sm={9} md={5} lg={4} xl={8}>
                        <Card>
                            <MKBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={1}
                                mt={1}
                                p={0}
                                mb={1}

                                textAlign="center"
                            >
                                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Evaluaciones completadas
                                </MKTypography>
                            </MKBox>

                            <MKBox pt={4} pb={3} px={3}>

                                <MKBox component="form" role="form">

                                    <MKBox mb={2}>
                                        <MKInput type="filter" label="NOMBRE, APELLIDO, DEPORTE, CLUB" fullWidth
                                                 onChange={searchChange}
                                                 onKeyDown={manejarKeyDown}
                                        />

                                    </MKBox>

                                    <MKBox mb={2}>

                                        <div style={{textAlign: "left"}}>
                                            {isLoading ? (
                                                <p>Cargando...</p>
                                            ) : 
                                            // (
                                            //         usuariosYEvaluaciones != null && findAthlete !== '' ? 
                                            //         (
                                            //             usuariosYEvaluaciones
                                            //             .filter((player) => 
                                            //             {
                                            //                 return (
                                            //                 player.firstName.toLowerCase().includes(findAthlete) ||
                                            //                 player.lastName.toLowerCase().includes(findAthlete) ||
                                            //                 player.sport.toLowerCase().includes(findAthlete) ||
                                            //                 player.club.toLowerCase().includes(findAthlete)
                                            //                 );
                                            //             })
                                            //             .map((usuarioYEvaluacion) => (
                                            //             <li key={usuarioYEvaluacion.id} style={{marginBottom: "10px"}}>
                                            //                 <b>Nombre:</b> {usuarioYEvaluacion.firstName.toUpperCase()}&nbsp;
                                            //                 <b>Apellido:</b> {usuarioYEvaluacion.lastName.toUpperCase()}&nbsp;
                                            //                 <b>Deporte:</b> {usuarioYEvaluacion.sport.toUpperCase()}&nbsp;
                                            //                 <b>Club:</b> {usuarioYEvaluacion.club.toUpperCase()}&nbsp;
                                            //                 <button
                                            //                     style={{padding: '5px 10px'}}
                                            //                     onClick={(event) => {
                                            //                         event.preventDefault();
                                            //                         generarDocumento(usuarioYEvaluacion.evaluacion.ui_at);
                                            //                     }}
                                            //                 >
                                            //                     Descargar Word
                                            //                 </button>
                                            //                 &nbsp;&nbsp;
                                            //                 <button style={{padding: '5px 10px'}} onClick={(event) => {
                                            //                    event.preventDefault();
                                            //                    previsualizarDocumento(usuarioYEvaluacion.evaluacion.ui_at)
                                            //                 }}>Visualizar
                                            //                 </button>
                                            //             </li>
                                            //         ))
                                            // ) : (
                                            //     "Favor buscar el atleta deseado en el recuadro de arriba"
                                            // )
                                            // )
                                            (
                                                atletasYEvaluaciones !== null && findAthlete !== '' ? 
                                                         (        
                                                            atletasYEvaluaciones
                                                            .filter((atleta) =>
                                                            {
                                                                return (
                                                                    atleta.firstName.toLowerCase().includes(findAthlete) ||
                                                                    atleta.lastName.toLowerCase().includes(findAthlete) ||
                                                                    atleta.sport.toLowerCase().includes(findAthlete) ||
                                                                    atleta.club.toLowerCase().includes(findAthlete)
                                                                );
                                                                 })
                                                            .map((athletesTests) => (                             
                                                            <li key={athletesTests.id} style={{marginBottom: "40px"}}>
                                                                 <b>Nombre:</b> {athletesTests.firstName.toUpperCase()}&nbsp;
                                                                 <b>Apellido:</b> {athletesTests.lastName.toUpperCase()}&nbsp;
                                                                 <b>Deporte:</b> {athletesTests.sport.toUpperCase()}&nbsp;
                                                                 <b>Club:</b> {athletesTests.club.toUpperCase()}&nbsp;  

                                                                 {/* {athletesTests.assignedTests.map((test, index) => (
                                                                 <div key={index}>
                                                                 <b>Pruebas:</b> {test.testId.toUpperCase()}&nbsp;
                                                                 <b>Fecha:</b> {test.sysCreationDate.slice(6, 8) + "-" + test.sysCreationDate.slice(4, 6) + "-" + test.sysCreationDate.slice(0, 4)}&nbsp; */}
                                                                 {Object.entries(groupTestsByDate(athletesTests.assignedTests)).map(([date, testsForDate]) => (
                                                                <div key={date}>
                                                                <b>Fecha:</b> {date.slice(6, 8) + "-" + date.slice(4, 6) + "-" + date.slice(0, 4)}
                                                                {testsForDate.map((test, index) => (
                                                                    
                                                                    <div key={index}>
                                                                    <b>Pruebas:</b> {test.testId.toUpperCase()}&nbsp;
                                                                    {/* <b>Fecha:</b> {test.sysCreationDate.slice(6, 8) + "-" + test.sysCreationDate.slice(4, 6) + "-" + test.sysCreationDate.slice(0, 4)}&nbsp; */}
                                                                    </div>
                                                                 ))}
                                                                    <button
                                                                     style={{padding: '5px 10px'}}
                                                                     onClick={(event) => {
                                                                         event.preventDefault();
                                                                         const uiAt = testsForDate[0].uiAt;
                                                                         generarDocumento(uiAt);
                                                                     }}
                                                                 >
                                                                     Descargar Word
                                                                 </button>
                                                                 &nbsp;&nbsp;
                                                                 <button style={{padding: '5px 10px'}} onClick={(event) => {
                                                                    event.preventDefault();
                                                                    const uiAt = testsForDate[0].uiAt;
                                                                    previsualizarDocumento(uiAt)
                                                                    console.log(uiAt)
                                                                 }}>Visualizar
                                                                 </button>
                                                                  </div>
                                                                   ))}
                                                                 {/* <b>Pruebas:</b> {athletesTests.assignedTests[0].testId.toUpperCase()}&nbsp;
                                                                 <b>Fecha:</b> {athletesTests.assignedTests[0].sysCreationDate.slice(6,8)+"-"+athletesTests.assignedTests[0].sysCreationDate.slice(4,6)+"-"+athletesTests.assignedTests[0].sysCreationDate.slice(0,4)}&nbsp; */}
                                                          
                                                             </li>
                                                             ))
                                            ):(
                                         
                                                <p>Favor buscar el atleta deseado en el recuadro de arriba</p>
                                          
                                            )
                                            )
                                            }
                                            {/*{usuariosYEvaluaciones != null ? (*/}
                                            {/*    usuariosYEvaluaciones*/}
                                            {/*        .filter((player) => {*/}
                                            {/*            return findAthlete.toLowerCase() === ''*/}
                                            {/*                ? player*/}
                                            {/*                : player.firstName.toLowerCase().includes(findAthlete) +*/}
                                            {/*                player.lastName.toLowerCase().includes(findAthlete) +*/}
                                            {/*                player.sport.toLowerCase().includes(findAthlete) +*/}
                                            {/*                player.club.toLowerCase().includes(findAthlete)*/}
                                            {/*                ;*/}
                                            {/*        })*/}
                                            {/*        .map(usuarioYEvaluacion => (*/}

                                            {/*            <li key={usuarioYEvaluacion.id} style={{marginBottom: "10px"}}>*/}
                                            {/*                <b>Nombre:</b>{usuarioYEvaluacion.firstName.toUpperCase()}&nbsp;*/}
                                            {/*                <b>Apellido:</b>{usuarioYEvaluacion.lastName.toUpperCase()}&nbsp;*/}
                                            {/*                <b>Deporte:</b>{usuarioYEvaluacion.sport.toUpperCase()}&nbsp;*/}
                                            {/*                <b>Club:</b>{usuarioYEvaluacion.club.toUpperCase()}&nbsp;*/}

                                            {/*                <button style={{padding: '5px 10px'}} onClick={(event) => {*/}
                                            {/*                    event.preventDefault();*/}
                                            {/*                    generarDocumento(usuarioYEvaluacion.evaluacion.ui_at)*/}
                                            {/*                }}>Descargar Word*/}
                                            {/*                </button>*/}
                                            {/*                &nbsp;&nbsp;*/}
                                            {/*                /!*<button style={{padding: '5px 10px'}} onClick={(event) => {*!/*/}
                                            {/*                /!*    event.preventDefault();*!/*/}
                                            {/*                /!*    previsualizarDocumento(usuarioYEvaluacion.evaluacion.ui_at)*!/*/}
                                            {/*                /!*}}>Visualizar*!/*/}
                                            {/*                /!*</button>*!/*/}
                                            {/*            </li>*/}

                                            {/*        ))*/}
                                            {/*) : ("no hay data")*/}
                                            {/*}*/}
                                        </div>

                                    </MKBox>
                                    <MKBox mt={4} mb={3} xl={10}>

                                        <MKButton variant="gradient" color="info"
                                                  onClick={handleBack}>
                                            Volver
                                        </MKButton>
                                    </MKBox>
                                </MKBox>
                            </MKBox>
                        </Card>
                    </Grid>
                </Grid>
            </MKBox>


        </>

    );
}

export default EvalCompletadas;
